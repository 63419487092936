import {useI18n} from 'vue-i18n';

export default function useTranslation() {
	const {t, locale, messages} = useI18n({
		// inheritLocale: true,
		useScope: 'global',
	});

	function getI18nArray(key: string): string[] {
		if (!messages.value) {
			return [];
		}
		const messageList: any = messages;
		const currentLocale = locale.value; // Aktuelle Sprache holen
		const keys = key.split('.'); // Schlüssel hierarchisch aufteilen
		let result: any = messageList.value[currentLocale]; // Start beim Sprach-Root

		for (const k of keys) {
			if (result[k] !== undefined) {
				result = result[k]; // Tiefer navigieren
			} else {
				console.error(`Key "${key}" not found in locale "${currentLocale}"`);
				return [];
			}
		}

		if (Array.isArray(result)) {
			return result.map(item => item.source);
		} else {
			console.error(`Key "${key}" does not resolve to an array`);
			return [];
		}
	}

	return {
		t, locale, messages, getI18nArray
	}
}
