import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "grid place-items-center h-screen justify-center sm:p-1 md:p-3",
  style: {"height":"auto"}
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex justify-center items-center" }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showSpinner === true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: _normalizeClass(['animate-spin rounded-full border-t-2 border-b-2', _ctx.spinnerColor + ' ' + _ctx.spinnerSize])
            }, null, 2)
          ])
        ]))
      : (_ctx.showSpinner === false && _ctx.showSpinnerAlternativeText === true)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t("loading.DefaultSpinner.spinnerAlternativeText")), 1))
        : _createCommentVNode("", true)
  ], 512)), [
    [_vShow, _ctx.showSpinner === true || _ctx.showSpinnerAlternativeText === true]
  ])
}