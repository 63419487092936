import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, withKeys as _withKeys, normalizeClass as _normalizeClass, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "px-1 mb-2 lg:p-2 lg:px-4 shadowSearchbar margin-auto" }
const _hoisted_2 = {
  key: 0,
  class: "relative rounded-xl inset-0 h-12 bg-gr-primar-100/50 flex items-center justify-center z-10 border-4 border-gr-primary border-dashed"
}
const _hoisted_3 = {
  key: 1,
  class: /*@__PURE__*/_normalizeClass(['flex items-center'])
}
const _hoisted_4 = {
  key: 1,
  class: "pl-5 sm:pl-8 py-2"
}
const _hoisted_5 = { class: "w-full" }
const _hoisted_6 = { class: "absolute" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "" }
const _hoisted_9 = {
  key: 0,
  class: "ml-6 self-center text-xs px-4 py-1 cursor-default rounded-3xl py-0 bg-black dark:bg-white text-white dark:text-black"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaperClipIcon = _resolveComponent("PaperClipIcon")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_voice_action = _resolveComponent("voice-action")!
  const _component_ProAdDialog = _resolveComponent("ProAdDialog")!
  const _component_PopoverOverlay = _resolveComponent("PopoverOverlay")!
  const _component_TransitionChild = _resolveComponent("TransitionChild")!
  const _component_PopoverPanel = _resolveComponent("PopoverPanel")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!
  const _component_Popover = _resolveComponent("Popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Popover, {
      class: _normalizeClass(['relative rounded-xl fixed inset-x-0 flex flex-col-reverse', _ctx.open ? 'z-40' : 'z-20'])
    }, {
      default: _withCtx(({ open }) => [
        _createElementVNode("div", {
          class: _normalizeClass(['relative z-10 rounded-3xl bg-gray-200 dark:bg-gr-dark overflow-hidden', _ctx.isDragging ? 'text-input-container' : '' ]),
          onDragover: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleDragOver && _ctx.handleDragOver(...args)), ["prevent"])),
          onDragenter: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleDragEnter && _ctx.handleDragEnter(...args)), ["prevent"])),
          onDragleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleDragLeave && _ctx.handleDragLeave(...args))),
          onDrop: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleDrop && _ctx.handleDrop(...args)), ["prevent"])),
          onPaste: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handlePaste && _ctx.handlePaste(...args)))
        }, [
          (_ctx.isDragging)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[8] || (_cache[8] = [
                _createElementVNode("p", { class: "text-gr-primary2 font-bold" }, "Drop your file here", -1)
              ])))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_ctx.mode === 'default' && _ctx.uploadModeActive)
                  ? (_openBlock(), _createBlock(_component_PopoverButton, {
                      key: 0,
                      class: "focus:outline-none font-semibold items-center pl-2 sm:pl-4 py-2 font-medium"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_PaperClipIcon, {
                          class: "h-5 w-5 font-extrabold text-gray-500 dark:text-gray-400 mr-6",
                          "aria-hidden": "true"
                        })
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createElementBlock("div", _hoisted_4)),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _withDirectives(_createVNode(_component_ion_icon, {
                      class: "icon-btn absolute cursor-pointer mt-[0.95em] md:mt-[0.75em] right-0 mr-0 text-xl z-10",
                      icon: _ctx.icons.close,
                      onClick: _ctx.clearSearch
                    }, null, 8, ["icon", "onClick"]), [
                      [_vShow, false]
                    ])
                  ]),
                  _createVNode(_component_ion_textarea, {
                    ref: "textareaRef",
                    disabled: _ctx.isDisabled,
                    rows: _ctx.computedMaxRows,
                    wrap: _ctx.wrap,
                    type: "search",
                    enterkeyhint: "enter",
                    placeholder: _ctx.placeholder,
                    spellcheck: !_ctx.isMobileDevice,
                    class: "bg-transparent customSearch mt-0 w-full z-0",
                    onKeydown: _withKeys(_ctx.searchOnEnter, ["enter"]),
                    animated: false,
                    onIonClear: _ctx.clearSearch,
                    onKeyup: _withKeys(_ctx.clearSearch, ["esc"]),
                    modelValue: _ctx.textInputState,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textInputState) = $event)),
                    "auto-grow": _ctx.autoGrow,
                    autofocus: true,
                    inputmode: "text"
                  }, null, 8, ["disabled", "rows", "wrap", "placeholder", "spellcheck", "onKeydown", "onIonClear", "onKeyup", "modelValue", "auto-grow"])
                ]),
                _withDirectives(_createVNode(_component_voice_action, {
                  onSendVoiceText: _ctx.updateTextAreaValueByVoice,
                  onEndVoiceRecognition: _ctx.updateTextAreaValueByVoice,
                  class: "icon-btn text-xl md:text-2xl cursor-pointer mt-1.5 pr-4 px-0 sm:px-2"
                }, null, 8, ["onSendVoiceText", "onEndVoiceRecognition"]), [
                  [_vShow, _ctx.activateWebSpeech]
                ]),
                _createVNode(_component_ion_icon, {
                  onClick: _ctx.search,
                  class: "icon-btn text-3xl md:text-2xl cursor-pointer pr-4 sm:pr-6",
                  icon: _ctx.icons.send
                }, null, 8, ["onClick", "icon"])
              ]))
        ], 34),
        _createVNode(_component_ProAdDialog, {
          title: _ctx.$t('becomeAMemberTitle'),
          description: _ctx.$t('becomeAMemberDescription'),
          "cancel-button-name": _ctx.$t('becomeAMemberCancel'),
          "confirm-button-name": _ctx.$t('becomeAMemberConfirm'),
          type: "success",
          visible: _ctx.showProAdDialog,
          onCancelConfirm: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showProAdDialog = false))
        }, null, 8, ["title", "description", "cancel-button-name", "confirm-button-name", "visible"]),
        (_ctx.uploadModeActive)
          ? (_openBlock(), _createBlock(_component_TransitionRoot, {
              key: 0,
              as: "template",
              show: open
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(['absolute w-full mb-16', _ctx.getComputedMaxRows() >= 2 && _ctx.getComputedMaxRows() < 5? 'mb-18' : '', _ctx.getComputedMaxRows() >= 5 && _ctx.getComputedMaxRows() <= 8? 'mb-[12.5em]' : '',  _ctx.getComputedMaxRows() > 8 ? 'mb-44' : ''])
                }, [
                  _createVNode(_component_TransitionChild, {
                    as: "template",
                    enter: "transition-opacity ease-linear duration-300",
                    "enter-from": "opacity-0",
                    "enter-to": "opacity-100",
                    leave: "transition-opacity ease-linear duration-300",
                    "leave-from": "opacity-100",
                    "leave-to": "opacity-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_PopoverOverlay, { class: "fixed inset-0" })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_TransitionChild, {
                    as: "template",
                    enter: "transition ease-in-out duration-300 transform",
                    "enter-from": "translate-y-full",
                    "enter-to": "translate-y-0",
                    leave: "transition ease-in-out duration-300 transform",
                    "leave-from": "translate-y-0",
                    "leave-to": "translate-y-full"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_PopoverPanel, { class: "relative mb-4 rounded-3xl bg-gray-200 dark:bg-gr-dark text-gray-500 dark:text-gray-40 px-4 py-6 z-40" }, {
                        default: _withCtx(({ close }) => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inputMethode, (item) => {
                            return (_openBlock(), _createElementBlock("ul", {
                              role: "list",
                              class: "divide-y",
                              key: item.name
                            }, [
                              _createElementVNode("li", {
                                class: _normalizeClass(['p-4 flex items-center font-mono', item.pro === true ? '' : 'text-gray-500 dark:text-gray-400', item.active ? 'font-bold cursor-pointer' : 'opacity-65 cursor-not-allowed']),
                                onClick: ($event: any) => (item.active ? _ctx.createUploadPrompt(item.type, close) : undefined)
                              }, [
                                (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
                                  class: "h-5 w-5 mr-4",
                                  "aria-hidden": "true"
                                })),
                                _createElementVNode("p", _hoisted_8, _toDisplayString(item.name), 1),
                                (item.coming_soon)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, " Coming soon "))
                                  : _createCommentVNode("", true),
                                (item.pro === true)
                                  ? (_openBlock(), _createElementBlock("button", {
                                      key: 1,
                                      onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showProAdDialog = true)),
                                      class: "cursor-pointer ml-6 self-center text-xs px-4 py-1 rounded-3xl py-0 bg-black dark:bg-white text-white dark:text-black",
                                      style: {"font-family":"Nunito","font-weight":"700"}
                                    }, "pro "))
                                  : _createCommentVNode("", true)
                              ], 10, _hoisted_7)
                            ]))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ], 2)
              ]),
              _: 2
            }, 1032, ["show"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}