<template>
	<div class="p-2 sm:p-4 pt-0 pb-3 sm:pt-0 hidden lg:flex flex-row justify-end" v-if="isAuthenticated">
		<button @click="addNewGroupAndMarkAsActive" :class="['flex-auto inline-flex rounded-full bg-white text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50', isMenuOpen ? 'px-3.5 py-2' : 'tooltip tooltip-top px-2.5 py-1']" data-tip="new Chat">
			<plus-circle-icon :class="['text-gray-400 dark:text-gray-500 group-hover:text-gray-500 flex-shrink-0', !isMenuOpen ? 'w-full h-8' : 'h-6 w-6']"/>
				<span v-if="isMenuOpen"
							class="truncate text-base font-semibold pl-3 pr-1 text-gray-500 group-hover:text-gr-gray-500 hidden lg:block">{{ $t('newChat') }}</span>
		</button>
		<button v-if="isMenuOpen" @click="refreshGroup" class="px-2 py-2 tooltip tooltip-top" :data-tip="$t('version.newVersionAvailable.button.reload')">
			<arrow-path-icon class="text-gray-400 dark:text-gray-500 group-hover:text-gray-500 flex-shrink-0 h-6 w-6"/>
		</button>
	</div>

	<div class="mx-2 sm:mx-4 my-0 sm:my-0 bg-gray-200 dark:bg-gr-dark rounded-xl hidden lg:block text-right" v-if="selectedGroup">
		<div class="flex justify-between pl-2">
			<button v-if="!isSelectedEditable" @click="selectGroup(selectedGroup)" class="max-w-[10em] px-2 py-2 flex tooltip tooltip-top break-words"
							data-tip="active Chat" @dblclick="() => makeGroupEditable(selectedGroup.uuid)"
							@blur="event => updateGroupName(event, selectedGroup.uuid)"
							@keydown.enter="event => updateGroupName(event, selectedGroup.uuid)">
				<ChatBubbleLeftIcon class="text-gray-400 dark:text-gray-500 group-hover:text-gray-500 flex-shrink-0 h-6 w-6"/>
				<span
						:class="['truncate text-base font-semibold pl-3 pr-1 text-gray-500 group-hover:text-gr-gray-500', isMenuOpen ? 'hidden lg:block' : 'sm:block lg:hidden']" :contenteditable="isEditable[selectedGroup.uuid]">{{ selectedGroup.getName() }}</span>
			</button>
			<div class="flex items-center pr-2" v-if="isAuthenticated">
				<DynamicMenu key="selectedGroup" :menuItems="groupMenuItems" :reference="selectedGroup.uuid" @menu-click="handleGroupMenuAction" />
			</div>
		</div>
	</div>

	<ConfirmWithInputFieldDialog
			:input-value="editGroupValue"
			:visible="showEditGroupDialog"
			:icon="PencilIcon"
			:input-placeholder="$t('group.change.name.placeholder')"
			type="success"
			@confirm="handleGroupEdit"
			:confirm-button-name="$t('group.change.confirm')"
			:cancel-button-name="$t('group.change.cancel')"
			@cancel-confirm="showEditGroupDialog = false">
	</ConfirmWithInputFieldDialog>

	<ConfirmDialog :title="$t('group.delete.title')"
								 :description="$t('group.delete.description')"
								 :cancel-button-name="$t('group.delete.cancel')"
								 :confirm-button-name="$t('group.delete.confirm')"
								 type="alert"
								 @confirm="handleGroupDelete"
								 @cancel-confirm="showConfirmDeleteGroupDialog = false"
								 :visible="showConfirmDeleteGroupDialog">
	</ConfirmDialog>

	<div v-if="isAuthenticated">
		<div v-if="isGroupLoading">
			<div role="status" class="text-center mt-2 mb-2">
				<svg aria-hidden="true" class="inline justify-center w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gr-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
					<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
				</svg>
				<span class="sr-only">Loading...</span>
			</div>
		</div>
		<div>
			<div v-if="!mobileMenuSidebar" class="mt-1 m-2 sm:m-4 mb-0 sm:mb-0 hidden lg:block text-right rounded-2xl">
				<div class="hidden lg:block text-right max-h-[60vh] rounded-xl overflow-y-auto overflow-x-hidden">
					<div class="pl-2 hidden lg:block text-right mt-2" v-for="(group, index) in groupedHistory" :key="index">
						<!-- Collapse button -->
						<button @click="toggleCollapse(index)" class="flex items-center justify-start w-full text-gray-500 text-sm mb-2">
							<template v-if="!group.collapsed">
								<ArrowSmallUpIcon class="h-4 w-4 mr-1" />
							</template>
							<template v-if="group.collapsed">
								<ArrowSmallDownIcon class="h-4 w-4 mr-1" />
							</template>
							<span v-if="isMenuOpen">{{ group.date }}</span>
						</button>

						<!-- Collapsible content -->
						<div v-if="!group.collapsed">
							<!-- Display buttons in the group -->
							<div v-for="historyGroup in group.groups" :key="historyGroup.id" :class="{ 'bg-gray-200 dark:bg-gr-dark rounded-xl': selectedGroup && selectedGroup.uuid === historyGroup.uuid }">
								<div class="flex justify-between pl-2 pr-5">
									<button @click="selectGroup(historyGroup)" class="py-2 flex tooltip tooltip-top" :data-tip="historyGroup.getName()">
										<ChatBubbleLeftIcon class="text-gray-400 dark:text-gray-500 group-hover:text-gray-500 flex-shrink-0 h-6 w-6"/>
										<span v-if="isMenuOpen" class="max-w-[10em] truncate text-base font-semibold pl-3 pr-1 text-gray-500 group-hover:text-gr-gray-500 hidden lg:block">{{ historyGroup.getName() }}</span>
										<span class="truncate text-base font-semibold pl-3 pr-1 text-gray-500 group-hover:text-gr-gray-500 hidden sm:block lg:hidden">{{ historyGroup.getName() }}</span>
									</button>
<!--									<div class="flex items-center pr-2" v-if="isAuthenticated">-->
<!--										<DynamicMenu key="desktop" :menuItems="groupMenuItems" :reference="historyGroup.uuid" @menu-click="handleGroupMenuAction" />-->
<!--									</div>-->
								</div>
							</div>
						</div>
					</div>

					<div class="flex" v-if="isMenuOpen">
						<button v-if="hasMoreGroupsToLoad" @click="loadAllGroups" type="button" class="m-2 rounded items-center justify-center flex bg-white px-4 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full">{{$t('enterprise.stats.models.show_more')}}</button>
					</div>
				</div>
			</div>

			<div v-else-if="mobileMenuSidebar">
				<TransitionRoot as="template" :show="mobileMenuSidebar">
					<Dialog as="div" class="relative z-9 lg:hidden" @close="setMobileMenuSidebar(false)">
						<!-- Existing mobile sidebar content -->
						<div class="fixed inset-0 flex">
							<TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
															 enter-from="-translate-x-full" enter-to="translate-x-0"
															 leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0"
															 leave-to="-translate-x-full">
								<DialogPanel class="relative flex w-full flex-1">
									<TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0"
																	 enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100"
																	 leave-to="opacity-0">
										<div class="m-4 mx-8 mt-28 absolute flex justify-end">
											<button type="button" class="-m-2.5 p-2.5" @click="setMobileMenuSidebar(false)">
												<span class="sr-only">Close sidebar</span>
												<XMarkIcon class="h-6 w-6" aria-hidden="true"/>
											</button>
										</div>
									</TransitionChild>
									<div v-if="isNewChatLoading">
										<div role="status" class="text-center mt-2 mb-2 absolute h-full w-full flex items-center justify-center backdrop-filter backdrop-blur-sm">
											<svg aria-hidden="true" class="inline justify-center w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-gr-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
												<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
											</svg>
											<span class="sr-only">Loading...</span>
										</div>
									</div>
									<!-- Sidebar component, swap this element with another sidebar if you like -->
									<div
											class="m-4 mt-24 max-h-[60vh] flex grow rounded-2xl bg-white dark:bg-gr-darkest shadow-md flex-col gap-y-0 overflow-y-auto px-6 pb-2">
										<div class="flex h-16 shrink-0 items-center">
										</div>
										<nav class="flex flex-1 flex-col">
											<ul role="list" class="flex flex-1 flex-col gap-y-7">
												<li>
													<ul role="list" class="-mx-2 space-y-2">
<!--														TODOO ADD INSTANT LOADING and ripple effect-->
														<button type="button" class="w-full self-center justify-center flex lg:hidden rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
																		@click="addNewGroupAndMarkAsActive">
                        <span class="flex"><PlusIcon class="mx-2 h-6 w-6"
																										 aria-hidden="true"/></span>
															<span class="">{{ $t('newChat') }}</span>
														</button>
														<li v-if="selectedGroup" class="flex">
															<div class="bg-gray-200 dark:bg-gr-dark text-gray-500 w-full group flex justify-between rounded-2xl">
																<a v-if="!isSelectedEditable" @click="selectGroup(selectedGroup)"
																	 class="flex gap-x-3 px-4 py-2 text-sm leading-6 font-semibold tooltip-top whitespace-nowrap"
																	 data-tip="active Chat">
																	<ChatBubbleLeftIcon class="h-6 w-6 shrink-0" aria-hidden="true"/>
																	{{ selectedGroup.getName() }}
																</a>
																<div class="flex items-center pr-2" v-if="isAuthenticated">
																	<DynamicMenu key="mobile-selected" :menuItems="groupMenuItems" :reference="selectedGroup.uuid" @menu-click="handleGroupMenuAction" />
																</div>
															</div>
														</li>
													</ul>
												</li>
											</ul>
										</nav>

										<!-- History groups section -->
										<div class="p-4">
											<h3 :class="['group flex gap-x-3 rounded-2xl pt-1 pb-3 text-sm leading-6 font-semibold', isHistoryGroupsCollapsed ? '' : '']">
												<ClockIcon class="h-6 w-6 shrink-0" aria-hidden="true"/>
												{{ $t('allChats') }}
											</h3>
											<ul>
												<li v-for="(group, index) in groupedHistory" :key="index">
													<!-- Collapse button -->
													<button @click="toggleCollapse(index)" class="flex items-center justify-start w-full text-gray-500 text-sm mb-2">
														<template v-if="!group.collapsed">
															<ArrowSmallUpIcon class="h-4 w-4 mr-1"/>
														</template>
														<template v-if="group.collapsed">
															<ArrowSmallDownIcon class="h-4 w-4 mr-1"/>
														</template>
														<span>{{ group.date }}</span>
													</button>

													<!-- Collapsible content -->
													<div v-if="!group.collapsed">
														<ul>
															<li v-for="historyGroup in group.groups" :key="historyGroup.id">
																<!-- Display buttons in the group -->
																<div :class="['py-2', selectedGroup && selectedGroup.uuid === historyGroup.uuid ? 'bg-gray-200 dark:bg-gr-dark rounded-xl' : '']">
																	<div class="flex justify-start w-full">
																		<button @click="selectGroup(historyGroup)" class="tooltip tooltip-top contents"
																						:data-tip="historyGroup.getName()">
																			<ChatBubbleLeftIcon class="text-gray-400 dark:text-gray-500 group-hover:text-gray-500 flex-shrink-0 h-6 w-6"/>
																			<span class="truncate text-left text-base font-semibold pl-3 pr-1 text-gray-500 group-hover:text-gr-gray-500 whitespace-nowrap">{{ historyGroup.getName() }}</span>
																		</button>
																		<div class="flex justify-end items-center pr-2" style="flex: auto" v-if="isAuthenticated">
																				<DynamicMenu key="mobile" :menuItems="groupMenuItems" :reference="historyGroup.uuid" @menu-click="handleGroupMenuAction" />
																		</div>
																	</div>
																</div>
															</li>
														</ul>
													</div>
												</li>
											</ul>
										</div>
									</div>
								</DialogPanel>
							</TransitionChild>
						</div>
					</Dialog>
				</TransitionRoot>
			</div>

		</div>
	</div>

</template>

<script lang="ts" setup>
import {onMounted, defineProps, ref, computed, reactive, watch, defineEmits} from 'vue';
import {
	ChatBubbleLeftIcon, PlusCircleIcon, ClockIcon, ArrowSmallDownIcon, ArrowSmallUpIcon, TrashIcon, PencilIcon, ArrowPathIcon
} from '@heroicons/vue/20/solid';
import useTranslation from '@/composable/translation/useTranslation';
import router from '@/router';
import useAssistant from '@/composable/greeve/useAssistant';
import {SearchGroup} from '@/greeve/search/group/search_group.type';
import useAuth from '@/composable/auth/useAuth';
import {SearchGroupList} from '@/greeve/search/group/search_group_list.type';
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from '@headlessui/vue';
import {PlusIcon, XMarkIcon,
} from '@heroicons/vue/24/outline';
import useCustomStore from '@/composable/custom/useCustomStore';
import {useRoute} from 'vue-router';
import DynamicMenu from '@/components/core/DynamicMenu.vue';
import { MenuItemType } from '@/greeve/core/menu_item_type';
import ConfirmWithInputFieldDialog from '@/components/modal/ConfirmWithInputFieldDialog.vue';
import ConfirmDialog from '@/components/modal/ConfirmDialog.vue';


const props = defineProps({
	group_uuid: {
		type: String,
		default: '',
	},
	group_reference: {
		type: String,
		default: '',
	},
	updateTimestamp: {
		type: Number,
		default: undefined,
	},
	hasEmptyGroup: {
		type: Boolean,
		default: false,
	},
	isMenuOpen: {
		type: Boolean,
		default: false,
	},
});

const emits = defineEmits(['groupSelected']);

const {t} = useTranslation();
const {isAuthenticated} = useAuth();
const {
	initSearchGroups,
	getGroupByUuid,
	searchGroups,
	addNewGroup,
	editGroup,
	deleteGroupByUuid,
	lastGroupUuid,
} = useAssistant();
const selectedGroup = ref<SearchGroup | any>();
const isGroupLoading = ref(false);
const isNewChatLoading = ref(false);
const isHistoryGroupsCollapsed = ref(false);
const isEditable: any = reactive([]);
const isSelectedEditable = ref(false);
const {
	mobileMenuSidebar,
	setMobileMenuSidebar,
	setIsGlobalLoading,
	isLoadAllGroups,
	setLoadAllGroups,
} = useCustomStore();
const route = useRoute();
const groupedHistory = ref<{ date: string; groups: SearchGroup[] | SearchGroupList, collapsed: boolean }[]>([]);
const hasMoreGroupsToLoad = ref(false);
const showEditGroupDialog = ref(false);
const showConfirmDeleteGroupDialog = ref(false);
const actionGroupUuid = ref();

const editGroupValue = computed(() => {
	if (actionGroupUuid.value) {
		const groupToEdit = getGroupByUuid(actionGroupUuid.value);
		if (groupToEdit) {
			return groupToEdit.getName();
		}
	}
	return '';
});

const groupMenuItems = ref<MenuItemType[]>([
	{label: t('group.change.button'), action: handleShowEditConfirmDialog, icon: PencilIcon},
	{label: t('group.delete.button'), action: handleShowDeleteGroupConfirmDialog, icon: TrashIcon},
]);

function handleGroupMenuAction(item: MenuItemType, reference?: string) {
	item.action(reference); // Execute the action associated with the clicked menu item
}

function handleShowEditConfirmDialog(reference: string) {
	actionGroupUuid.value = reference;
	showEditGroupDialog.value = true;
}

function handleShowDeleteGroupConfirmDialog(reference: string) {
	actionGroupUuid.value = reference;
	showConfirmDeleteGroupDialog.value = true;
}

function handleGroupDelete() {
	if (actionGroupUuid.value) {
		try {
			setIsGlobalLoading(true);
			const loadingTimeoutId = setTimeout(() => {
				setIsGlobalLoading(false);
			}, 10000);
			showConfirmDeleteGroupDialog.value = false;
			deleteGroupByUuid(actionGroupUuid.value).then(() => {
				if (searchGroups.value) {
					const activeGroup = searchGroups.value?.getNewestGroup();
					if (activeGroup) {
						updateGroup(activeGroup);
					}
				}
				setIsGlobalLoading(false);
				clearTimeout(loadingTimeoutId);
			});
		} catch (e) {
			setIsGlobalLoading(false);
		}
	}
}

function handleGroupEdit(inputValue: string) {
	if (actionGroupUuid.value) {
		try {
			setIsGlobalLoading(true);
			const loadingTimeoutId = setTimeout(() => {
				setIsGlobalLoading(false);
			}, 10000);
			showEditGroupDialog.value = false;
			editGroup(actionGroupUuid.value, inputValue, false).then(() => {
				const group = getGroupByUuid(actionGroupUuid.value);
				if (group) {
					updateGroup(group);
				}
				setIsGlobalLoading(false);
				clearTimeout(loadingTimeoutId);
			});
		} catch (e) {
			setIsGlobalLoading(false);
		}
	}
}

function selectGroup(searchGroup: SearchGroup) {
	selectedGroup.value = searchGroup;
	setMobileMenuSidebar(false);
	document.title = 'greeve - ' + searchGroup.getName(50);
	router.push(
			'/assistant/chat/' + searchGroup.getUrlName() + '/' + searchGroup.getUuidEncoded() + '/' + searchGroup.reference);
	emits('groupSelected');
	isGroupLoading.value = false;
	isNewChatLoading.value = false;
}

function addNewGroupAndMarkAsActive() {
	if (!isAuthenticated.value) {
		return;
	}
	isNewChatLoading.value = true;
	const firstNewGroup = searchGroups.value?.getFirstNewActiveGroupWithoutItems();
	if (firstNewGroup) {
		selectedGroup.value = firstNewGroup;
		selectGroup(selectedGroup.value);
	} else {
		addNewGroup().then((group) => {
			selectedGroup.value = group;
			selectGroup(selectedGroup.value);
		});
	}
}

//TODO remove
const makeGroupEditable = (groupUuid: string) => {
	isEditable[groupUuid] = false;
};

async function updateGroupName(event: FocusEvent, groupUuid: string) {
	const updateGroup = getGroupByUuid(groupUuid);
	if (!updateGroup) {
		isEditable[groupUuid] = false;
		return;
	}
	try {
		isEditable[groupUuid] = false;
		const target = event.target as HTMLDivElement;
		if (target.textContent === updateGroup.getName()) {
			return;
		}
		await editGroup(updateGroup.uuid, target.textContent || '');
	} catch (e) {
		console.error(e);
	} finally {
		isEditable[groupUuid] = false;
	}
}

function updateGroup(searchGroupNew: SearchGroup) {
	if (searchGroupNew.uuid && searchGroupNew.reference) {
		selectedGroup.value = getGroupByUuid(searchGroupNew.uuid);
		if (selectedGroup.value) {
			isSelectedEditable.value = false;
			selectGroup(selectedGroup.value);
		}
	}
}

//TODO load groups without items

async function initGroups(loadAll = false) {
	if (isGroupLoading.value) {
		return;
	}
	const loadItems = false;
	isGroupLoading.value = true;
	const pageCount = loadAll ? 0 : 8;
	//TODO check if group selected and load first selected group
	if (useAuth().isAuthenticated.value && (!props.group_uuid || props.group_uuid.length === 0)) {
		initSearchGroups(true, loadItems, pageCount).then(() => {
			if (lastGroupUuid.value) {
				selectedGroup.value = getGroupByUuid(lastGroupUuid.value);
				if (selectedGroup.value) {
					selectGroup(selectedGroup.value);
				}
			} else {
				addNewGroupAndMarkAsActive();
			}
			groupHistoryByDate();
			isGroupLoading.value = false;
		});
	} else if (props.group_uuid && props.group_uuid.length > 0) {
		if (useAuth().isAuthenticated.value) {
			initSearchGroups(true, loadItems, pageCount).then(() => {
				selectedGroup.value = getGroupByUuid(props.group_uuid);
				if (selectedGroup.value) {
					selectGroup(selectedGroup.value);
				} else {
					addNewGroupAndMarkAsActive();
				}
				groupHistoryByDate();
				isGroupLoading.value = false;
			});
		} else {
			selectedGroup.value = getGroupByUuid(props.group_uuid);
			if (selectedGroup.value) {
				selectGroup(selectedGroup.value);
			} else {
				addNewGroupAndMarkAsActive();
			}
			groupHistoryByDate();
		}
	} else if (lastGroupUuid.value) {
		selectedGroup.value = getGroupByUuid(lastGroupUuid.value);
		if (selectedGroup.value) {
			selectGroup(selectedGroup.value);
		}
		groupHistoryByDate();
	} else {
		addNewGroupAndMarkAsActive();
		groupHistoryByDate();
	}
}

function getAllGroups(): SearchGroupList | undefined {
	if (searchGroups.value) {
		const ignoreList = [];
		if (selectedGroup.value && selectedGroup.value?.uuid) {
			ignoreList.push(selectedGroup.value.uuid);
		}
		return searchGroups.value;
	}
	return;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getAllGroupsWithoutSelected(): SearchGroup[] | SearchGroupList | undefined {
	if (searchGroups.value) {
		const ignoreList = [];
		if (selectedGroup.value && selectedGroup.value?.uuid) {
			ignoreList.push(selectedGroup.value.uuid);
		}
		return searchGroups.value?.filterList(ignoreList);
	}
	return;
}

// Method to group history items by date
const groupHistoryByDate = () => {
	const groups = getAllGroups();
	const grouped: { [key: string]: SearchGroup[] } = {};

	// Grouping items by date
	groups?.forEach(group => {
		if (group?.updated_at) {
			const dateKey = getDateKey(group?.updated_at);
			if (!grouped[dateKey]) {
				grouped[dateKey] = [];
			}
			grouped[dateKey].push(group);
		}
	});

	// Creating an array of grouped items with date
	const groupedArray = Object.keys(grouped).map(date => ({
		date,
		groups: grouped[date],
		collapsed: false,
	}));

	// Sorting by date (you can implement custom sorting logic here)
	groupedArray.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

	groupedHistory.value = groupedArray;
	hasMoreGroupsToLoad.value = !!(groups && groups?.hasMoreItemsToLoad());
};

// Helper method to get a date key (Today, Yesterday, etc.)
const getDateKey = (parsedDate: Date): string => {
	const today = new Date();
	const yesterday = new Date(today);
	yesterday.setDate(today.getDate() - 1);

	if (isSameDay(parsedDate, today)) {
		return t('historyGroup.today');
	} else if (isSameDay(parsedDate, yesterday)) {
		return t('historyGroup.yesterday');
	} else {
		return getMonthYear(parsedDate);
	}
};

// Helper method to check if two dates are the same day
const isSameDay = (date1: Date, date2: Date): boolean => {
	return date1.getFullYear() === date2.getFullYear() &&
			date1.getMonth() === date2.getMonth() &&
			date1.getDate() === date2.getDate();
};

// Helper method to get month and year in format "Month Year"
const getMonthYear = (date: Date): string => {
	const monthNumber = date.getMonth() + 1;
	const yearSuffix = (date.getFullYear() !== (new Date).getFullYear()) ? ` ${date.getFullYear()}` : '';
	return t(`historyGroup.${monthNumber}`) + yearSuffix;
};

// Method to toggle collapse state of a group
const toggleCollapse = (index: number) => {
	groupedHistory.value[index].collapsed = !groupedHistory.value[index].collapsed;
};

function loadAllGroups() {
	if (!isGroupLoading.value && isAuthenticated) {
		setLoadAllGroups(true);
		initGroups(true);
	}
}

function refreshGroup() {
	if (!isGroupLoading.value && isAuthenticated) {
		initGroups(isLoadAllGroups.value);
	}
}

watch(() => searchGroups.value,
		(newGroups: SearchGroupList | undefined, oldGroups: SearchGroupList | undefined) => {
			if (!isAuthenticated) {
				return;
			}

			if ((newGroups && newGroups !== oldGroups)) {
				let newSelectedGroup;
				if (selectedGroup.value && selectedGroup.value.uuid) {
					newSelectedGroup = newGroups.getItemByUuId(selectedGroup.value.uuid);
				} else if (lastGroupUuid.value) {
					newSelectedGroup = newGroups.getItemByUuId(lastGroupUuid.value);
				}

				if (newSelectedGroup && newSelectedGroup.name && newSelectedGroup.name?.length > 0 && newSelectedGroup?.name !==
						selectedGroup.value?.name) {
					updateGroup(newSelectedGroup);
				}
			}
		});

watch(() => props.updateTimestamp, (updateTimestamp) => {
	if (updateTimestamp && route.path.includes('/assistant') && !isGroupLoading.value && isAuthenticated) {
		initGroups(isLoadAllGroups.value);
	}
});

onMounted(() => {
	if (!isGroupLoading.value && isAuthenticated) {
		initGroups(isLoadAllGroups.value);
	}
});
</script>

<style lang="scss">
.slide-enter-active {
	transition: transform 0.3s;
}

.slide-leave-active {
	transition: transform 0.3s;
}

.slide-enter,
.slide-leave-to {
	transform: translateX(-100%);
}
</style>
