// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {AxiosError} from "axios";
import {GreeveApiGetResponse, ResponseError} from "@/greeve/api/api.interfaces";
import GreeveApiClient from "@/greeve/api/client";
import {SearchGroup} from '@/greeve/search/group/search_group.type';
import {
    SearchGroupFactory
} from '@/greeve/search/group/search_group_factory.type';
import {
    ResponseSearchItemAuthorization,
    SearchItemAuthorizationFactory,
} from '@/greeve/search/authorization/search_item_authorization_factory.type';
import {
    SearchItemAuthorization
} from '@/greeve/search/authorization/search_item_authorization.type';
import {SearchItemFactory} from '@/greeve/search/item/search_item_factory.type';
import {
    SearchItemTranscribeAudio
} from '@/greeve/search/item/type/subtype/search_item_transcribe_audio.type';
import {
    ImageModel,
    ImageSize,
    SearchItemImage,
} from '@/greeve/search/item/type/subtype/search_item_image.type';
import {SearchGroupList} from '@/greeve/search/group/search_group_list.type';
import {SearchItemList} from '@/greeve/search/item/search_item_list.type';
import {
    AbstractSearchItem
} from '@/greeve/search/item/abstract_search_item.type';
import useAuth from '@/composable/auth/useAuth';
import {
    AudioVoice,
    SearchItemTextToSpeech,
} from '@/greeve/search/item/type/subtype/search_item_text_to_speech.type';
import {
    GreeveSearchItemSubTypeInterface
} from '@/greeve/search/item/search_item.interface';
import useMediaFactory from '@/composable/greeve/useMediaFactory';
import {MediaFileInfo} from '@/greeve/media/media_file_info.type';
import {AssistantProvider} from '@/greeve/assistant/assistant_provider';
import useSystem from '@/composable/core/useSystem';

interface GreeveGetResponse extends GreeveApiGetResponse {
    data: {type: string, result: any};
    status: any;
}

class GreeveResponseError extends ResponseError {
}

export type UploadProgressCallback = (progress: number, event?: ProgressEvent) => void;

const GreeveApiAssistant = {

    getAssistantRoute: function(authRequired = false): string
    {
        if (authRequired && !useAuth().isAuthenticated.value) {
            throw new Error('Not Authorized');
        }

        if (useAuth().isAuthenticated.value || authRequired) {
            return '/user/assistant';
        }
        return '/assistant';
    },

    buildRoute: function(path: string, params?: Record<string, string | number | boolean>, authRequired = false): string {
        let baseUrl = this.getAssistantRoute(authRequired) + '/' + path;
        if (params) {
            const queryParams = Object.entries(params)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([key, value]) => value !== '' && value !== null && value !== undefined)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');
            if (queryParams) {
                baseUrl += `?${queryParams}`;
            }
        }
        return baseUrl;
    },

    getGroups: async function (loadItems = true, pageCount = 0): Promise<SearchGroupList|undefined> {
        try {
            const response = await GreeveApiClient.get(this.buildRoute('group', { loadItems: loadItems, perPage: pageCount}));
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const searchGroupFactory = new SearchGroupFactory();
                    return searchGroupFactory.createSearchGroupListByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    //TODO normal group call should be only via user and uuid, for this we need a new getGroupBySharedLink function
    getGroup_by_Uuid_Reference: async function (uuid: string, reference: string): Promise<SearchGroup|undefined> {
        try {
            const params:any = {
                'uuid': uuid,
                'reference': reference,
            };
            const response = await GreeveApiClient.post(this.buildRoute('group/shared'), params);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const searchGroupFactory = new SearchGroupFactory();
                    return searchGroupFactory.createSearchGroupByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },


    getGroup_by_Reference: async function (reference: string): Promise<SearchGroup|undefined> {
        try {
            const params:any = {
                'reference': reference,
            };
            const response = await GreeveApiClient.post(this.buildRoute('group/reference'), params);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const searchGroupFactory = new SearchGroupFactory();
                    return searchGroupFactory.createSearchGroupByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    getSearchItems_by_Group_Uuid_Reference: async function (uuid: string, reference: string): Promise<SearchItemList|undefined> {
        try {
            const params:any = {
                'uuid': uuid,
                'reference': reference,
            };
            const response = await GreeveApiClient.post(this.buildRoute('items/group'), params);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const searchItemFactory = new SearchItemFactory();
                    return searchItemFactory.createSearchItemListByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            console.error(error);
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    getGroups_by_References: async function (references: Record<string, any>): Promise<SearchGroupList|undefined> {
        try {
            const params:any = {
                'references': references,
            };
            const response = await GreeveApiClient.post(this.buildRoute('group/list/reference'), params);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const searchGroupFactory = new SearchGroupFactory();
                    return searchGroupFactory.createSearchGroupListByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    getItems: async function (): Promise<SearchItemList|undefined> {
        try {
            const response = await GreeveApiClient.get(this.buildRoute('item'));
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const searchItemFactory = new SearchItemFactory();
                    return searchItemFactory.createSearchItemListByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    getItemsByUuids: async function (uuids: string[]): Promise<SearchItemList|undefined> {
        try {
            const params:any = {
                'uuids': uuids,
            };
            const response = await GreeveApiClient.post(this.buildRoute('item/list'), params);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const searchItemFactory = new SearchItemFactory();
                    return searchItemFactory.createSearchItemListByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    getItem_by_Uuid: async function (uuid: string): Promise<AbstractSearchItem|undefined> {
        try {
            const response = await GreeveApiClient.get(this.buildRoute('item/'+uuid));
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const searchItemFactory = new SearchItemFactory();
                    return searchItemFactory.createSearchItemByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    getItem_by_AuthorizationUuid: async function (uuid: string): Promise<AbstractSearchItem|undefined> {
        try {
            const response = await GreeveApiClient.get(this.buildRoute('authorization/item/'+uuid));
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const searchItemFactory = new SearchItemFactory();
                    return searchItemFactory.createSearchItemByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    createGroup: async function (): Promise<SearchGroup|undefined> {
        try {
            const response = await GreeveApiClient.put(this.buildRoute('group/new'), null);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const searchGroupFactory = new SearchGroupFactory();
                    return searchGroupFactory.createSearchGroupByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    editGroup: async function (search_group_uuid: string, name: string): Promise<SearchGroup|undefined> {
        try {
            const params:any = {
                'search_group_uuid': search_group_uuid,
                'name': name,
            };
            const response = await GreeveApiClient.post(this.buildRoute('group/edit'), params);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const searchGroupFactory = new SearchGroupFactory();
                    return searchGroupFactory.createSearchGroupByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    generateGroupName: async function (search_group_uuid: string, prompt = '', subType: GreeveSearchItemSubTypeInterface|undefined = undefined): Promise<SearchGroup|undefined> {
        try {
            const params:any = {
                'search_group_uuid': search_group_uuid,
                'prompt': prompt,
                'sub_type': subType,
            };
            const response = await GreeveApiClient.post(this.buildRoute('group/generateGroupName'), params);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const searchGroupFactory = new SearchGroupFactory();
                    return searchGroupFactory.createSearchGroupByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    deleteGroup: async function (search_group_id: number): Promise<boolean|undefined> {
        try {
            const response = await GreeveApiClient.delete(this.buildRoute('group/delete/' + search_group_id, undefined, true));
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    return true;
                }
            }
            return false;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    deleteGroup_by_Reference: async function (search_group_uuid: string, search_group_reference: string): Promise<boolean|undefined> {
        try {
            const params:any = {
                'search_group_uuid': search_group_uuid,
                'search_group_reference': search_group_reference,
            };
            const response = await GreeveApiClient.post(this.buildRoute('group/delete_by_reference'), params);
            return !!response;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    deleteGroup_by_Uuid: async function (search_group_uuid: string): Promise<boolean|undefined> {
        try {
            const params:any = {
                'search_group_uuid': search_group_uuid,
            };
            const response = await GreeveApiClient.post(this.buildRoute('group/delete_by_uuid', undefined, true), params);
            return !!response;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },


    pinItem: async function (search_item_uuid: string, isPinned = true): Promise<AbstractSearchItem|undefined> {
        try {
            const params:any = {
                'search_item_uuid': search_item_uuid,
                'isPinned': isPinned,
            };
            const response = await GreeveApiClient.post(this.buildRoute('item/pin'), params);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const searchItemFactory = new SearchItemFactory();
                    return searchItemFactory.createSearchItemByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    hasEnoughQuota: async function (type: GreeveSearchItemSubTypeInterface, prompt: string|null = null, file: string|undefined = undefined): Promise<boolean> {
        try {
            const params:any = {
                'type': type,
                'prompt': prompt,
                'file': file
            };
            const response = await GreeveApiClient.post(this.buildRoute('quota/check'), params);
            const error = response.data.error ?? null;
            return !error;
        } catch (error: AxiosError|any) {
            console.error(error);
            return false;
        }
    },

    getEstimateCredits: async function (search_item_uuid: string|undefined = undefined, text: string|undefined = undefined, file: string|undefined = undefined, duration: number|undefined = undefined) {
        try {
            const params:any = {
                'search_item_uuid': search_item_uuid,
                'text': text,
                'file': file,
                'duration': duration,
            };
            const response = await GreeveApiClient.post(this.buildRoute('quota/estimated'), params);
            return {
                credits: response.data.data.credits, euroCosts: response.data.data.euroCosts
            }
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    createAuthorization: async function (prompt: string, createGroup = false, search_group_uuid: string|null = null, search_group_reference: string|null = null): Promise<SearchItemAuthorization|undefined> {
        try {
            const params:any = {
                'prompt': prompt,
                'force_create_group': createGroup,
                'search_group_uuid': search_group_uuid,
                'search_group_reference': search_group_reference,
            };
            const response = await GreeveApiClient.post(this.buildRoute('authorization/create'), params);
            if (response) {
                const responseData: ResponseSearchItemAuthorization|null|undefined = response.data.data;
                if (responseData) {
                    const searchItemAuthorizationFactory = new SearchItemAuthorizationFactory();
                    return searchItemAuthorizationFactory.createSearchItemAuthorizationByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    createSimplePrompt: async function (prompt: string, searchGroupUuid: string|null = null, searchGroupReference: string|null = null, provider: AssistantProvider|null = null): Promise<string|undefined> {
        try {
            const params:any = {
                'prompt': prompt,
                'search_group_uuid': searchGroupUuid,
                'search_group_reference': searchGroupReference,
                'provider': provider,
            };
            const response = await GreeveApiClient.post(this.buildRoute('chat/simple'), params);
            if (response) {
                console.log(response)
                const responseData = response.data.data;
                if (responseData) {
                    return (responseData as string);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    createSimpleSearchItemPrompt: async function (prompt: string, searchGroupUuid: string|null = null, searchGroupReference: string|null = null, provider: AssistantProvider|null = null): Promise<SearchItemImage|undefined> {
        try {
            const params:any = {
                'prompt': prompt,
                'search_group_uuid': searchGroupUuid,
                'search_group_reference': searchGroupReference,
                'provider': provider,
            };
            const response = await GreeveApiClient.post(this.buildRoute('chat/simple'), params);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const searchItemFactory = new SearchItemFactory();
                    return searchItemFactory.createSearchItemByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },


    createImage: async function (prompt: string, searchGroupUuid: string|null = null, searchGroupReference: string|null = null, size: ImageSize|null = null, hd = false, model: ImageModel|null = null): Promise<SearchItemImage|undefined> {
        try {
            const params:any = {
                'prompt': prompt,
                'search_group_uuid': searchGroupUuid,
                'search_group_reference': searchGroupReference,
                'size': size,
                'hd': hd,
                'model': model,
            };
            const response = await GreeveApiClient.post(this.buildRoute('image/create'), params);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const searchItemFactory = new SearchItemFactory();
                    return searchItemFactory.createSearchItemByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },


    createImageVariation: async function (mediaFileInfo: MediaFileInfo, searchGroupUuid: string|null = null, searchGroupReference: string|null = null, size: ImageSize|null = null, hd = false, model: ImageModel|null = null): Promise<SearchItemImage|undefined> {
        try {
            const params:any = {
                'media_file_info': mediaFileInfo,
                'search_group_uuid': searchGroupUuid,
                'search_group_reference': searchGroupReference,
                'size': size,
                'hd': hd,
                'model': model,
            };
            const response = await GreeveApiClient.post(this.buildRoute('image/variation'), params);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const searchItemFactory = new SearchItemFactory();
                    return searchItemFactory.createSearchItemByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },


    editImage: async function (mediaFileInfo: MediaFileInfo, prompt: string, negativeText: string|null = null, searchGroupUuid: string|null = null, searchGroupReference: string|null = null, size: ImageSize|null = null, hd = false, model: ImageModel|null = null): Promise<SearchItemImage|undefined> {
        try {
            const params:any = {
                'media_file_info': mediaFileInfo,
                'prompt': prompt,
                'negativeText': negativeText,
                'search_group_uuid': searchGroupUuid,
                'search_group_reference': searchGroupReference,
                'size': size,
                'hd': hd,
                'model': model,
            };
            const response = await GreeveApiClient.post(this.buildRoute('image/variation'), params);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const searchItemFactory = new SearchItemFactory();
                    return searchItemFactory.createSearchItemByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    createSpeechByText: async function (prompt: string, searchGroupUuid: string|null = null, searchGroupReference: string|null = null, voice: AudioVoice|null = null): Promise<SearchItemTextToSpeech|undefined> {
        try {
            const params:any = {
                'prompt': prompt,
                'voice': voice,
                'search_group_uuid': searchGroupUuid,
                'search_group_reference': searchGroupReference,
            };
            const response = await GreeveApiClient.post(this.buildRoute('text/audio'), params);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const searchItemFactory = new SearchItemFactory();
                    return searchItemFactory.createSearchItemByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },


    transcribeAudio: async function (mediaFileInfo: MediaFileInfo, searchGroupUuid: string|null = null, searchGroupReference: string|null = null, async = false): Promise<AbstractSearchItem|SearchItemTranscribeAudio|undefined> {
        try {
            const params:any = {
                'media_file_info': mediaFileInfo,
                'search_group_uuid': searchGroupUuid,
                'search_group_reference': searchGroupReference,
                'async': async,
            };
            const response = await GreeveApiClient.post(this.buildRoute('transcribe/audio'), params);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const searchItemFactory = new SearchItemFactory();
                    return searchItemFactory.createSearchItemByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },


    translate: async function (search_item_group_uuid: string, formData: FormData): Promise<AbstractSearchItem|SearchItemTranscribeAudio|undefined> {
        try {
            //TODO
            formData.append('search_item_group_uuid', search_item_group_uuid);

            const config: {[key: string]: any} = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            };
            const response = await GreeveApiClient.post(this.buildRoute('translate/audio'), formData, config);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const searchItemFactory = new SearchItemFactory();
                    return searchItemFactory.createSearchItemByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },


    //getGroups
    //getGroup
    //getItems
    //getItem

    //createChat --> post Request
    //createCompletion --> post Request
    //editImage
    //createVariationOfImages
    //voteSearchItem
    //share functions ??

    retryItem: async function (searchItemUuid: string): Promise<AbstractSearchItem|undefined> {
        try {
            if (!useAuth().isAuthenticated.value) {
                throw new Error('Not Authorized');
            }
            const params:any = {
                'search_item_uuid': searchItemUuid,
            };
            const response = await GreeveApiClient.post(this.buildRoute('regenerate/item'), params);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const searchItemFactory = new SearchItemFactory();
                    return searchItemFactory.createSearchItemByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    voteSearchResult: async function (searchItemUuid: string, type = 'good', state = 'done'): Promise<any|undefined> {
        try {
            const params:any = {
                'search_item_uuid': searchItemUuid,
                'type': type,
                'state': state,
            };
            if (type) {
                params.type = type
            }
            const response = await GreeveApiClient.post('/assistant/vote/search_result', params);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    return responseData.result;
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    // uploadFile: async function (file: Blob, onUploadProgress?: ProgressEventCallback): Promise<MediaFileInfo|undefined> {
    //     const formData = new FormData();
    //     formData.append('file', file);
    //
    //     try {
    //         const config: { [key: string]: any } = {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //             },
    //         };
    //         if (onUploadProgress) {
    //             config.onUploadProgress = (progressEvent: ProgressEvent) => {
    //                 onUploadProgress(progressEvent);
    //             };
    //         }
    //         const response = await GreeveApiClient.post(this.buildRoute('file/upload'), formData, config);
    //         return useMediaFactory().getMediaFactory().createMediaFileInfoByResponse(response.data.data);
    //     } catch (error: AxiosError | any) {
    //         if (error.response) {
    //             const errorMessage = error.response?.data?.message
    //                 ? error.response.data.message
    //                 : (error?.message ? error.message : '');
    //             throw new ResponseError(
    //                 error.response.status,
    //                 errorMessage
    //             );
    //         } else if (error.request) {
    //             console.log(error.request);
    //         } else {
    //             console.log('Error', error.message);
    //         }
    //     }
    // },


    uploadFile: async function (
        file: Blob,
        onUploadProgress?: UploadProgressCallback
    ): Promise<MediaFileInfo | undefined> {
        const formData = new FormData();
        formData.append("file", file);

        try {
            const config: { [key: string]: any } = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };

            const uploadFileProgressMaxValue = useSystem().getRandomNumber(35, 0);
            const uploadFileSimulateProgressValue = 100 - uploadFileProgressMaxValue;

            if (onUploadProgress) {
                config.onUploadProgress = (progressEvent: ProgressEvent) => {
                    if (progressEvent.total) {
                        const progress = Math.round((progressEvent.loaded * uploadFileProgressMaxValue) / progressEvent.total);
                        onUploadProgress?.(progress, progressEvent); // Übergebe sowohl Prozent als auch das Event
                    }
                };
            }

            // Dateigröße und Verarbeitungszeit
            const fileSizeMB = file.size / (1024 * 1024); // Dateigröße in MB
            const estimatedProcessingTime = Math.min(900000, fileSizeMB * 500); // Maximal 15 Minuten, 500ms/MB

            // Konfigurierbarer Buffer für größere Dateien
            const bufferForSmallFiles = Math.max(1000, (1 - fileSizeMB / 10) * 8500); // Bis zu 7s Buffer für kleine Dateien
            const largeFileBufferFactor = 900; // Konfigurierbarer Faktor für größere Dateien
            const bufferForLargeFiles = fileSizeMB > 10 ? fileSizeMB * largeFileBufferFactor : 0; // Zusätzlicher Buffer für große Dateien

            // Gesamte Verarbeitungszeit
            const totalProcessingTime = estimatedProcessingTime + bufferForSmallFiles + bufferForLargeFiles;

            // Simulate server processing progress (70-100%)
            let isServerDone = false; // Flag to indicate if server has completed
            const simulateServerProcessing = (resolve: () => void) => {
                let simulatedProgress = uploadFileProgressMaxValue;
                const startTime = performance.now();

                const interval = setInterval(() => {
                    const elapsedTime = performance.now() - startTime;

                    // Berechne den Fortschritt basierend auf der geschätzten Zeit + Buffer
                    simulatedProgress = uploadFileProgressMaxValue + Math.round((elapsedTime / totalProcessingTime) * uploadFileSimulateProgressValue);

                    // Setze Fortschritt auf 100%, wenn Backend schneller ist
                    if (isServerDone || simulatedProgress >= 100) {
                        onUploadProgress?.(100); // Jump to 100%
                        clearInterval(interval);
                        resolve(); // Beende Simulation
                        return;
                    }

                    // Fortschritt aktualisieren
                    onUploadProgress?.(simulatedProgress);
                }, 100); // Update alle 100ms
            };
            //

            // Wait for both the API call and simulated progress
            const [response] = await Promise.all([
                GreeveApiClient.post(this.buildRoute("file/upload"), formData, config).then((res) => {
                    isServerDone = true; // Mark server processing as done
                    return res; // Pass response to the next step
                }),
                new Promise<void>(simulateServerProcessing),
            ]);

            // Ensure progress ends at 100%
            onUploadProgress?.(100);

            return useMediaFactory()
            .getMediaFactory()
            .createMediaFileInfoByResponse(response.data.data);
        } catch (error: AxiosError | any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message || error.message || "";
                throw new ResponseError(error.response.status, errorMessage);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
        }
    },

}

export {GreeveApiAssistant, GreeveGetResponse, GreeveResponseError};
