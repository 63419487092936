<template>
	<div v-if="contentSearchItem">
		<template v-if="contentSearchItem?.isInteractionSearchItemUpload()">
			<output-card :card-reference="'prompt-'+contentSearchItem?.uuid"
									 :card-content="''" :item-uuid="contentSearchItem?.uuid" :is-pinned="contentSearchItem?.is_pinned"
									 @pin-item="contentSearchItem?.is_pinned != contentSearchItem?.is_pinned"
									 :card-interaction="false" :card-info="false" :reverse-side="false">
				<template v-slot:CardContent>
					<div v-if="isLoading" class="justify-center items-center">
						<h2 v-if="currentLoadingText" class="text-gray-200 text-center">{{ currentLoadingText }}</h2>
						<default-spinner :show-spinner="true"
														 show-spinner-alternative-text="show-spinner-alternative-text"
														 :spinner-color="'bg-gradient-to-b from-orange-500 to-yellow-300'"
														 :spinner-size="'h-32 w-32'"></default-spinner>
						<div class="mt-4 text-center font-bold text-bp-light">{{ getUploadLoadingDescription }}</div>
					</div>
					<div v-else>
						<h1 class="">{{ $t('assistant.output.upload.file.title') }}</h1>
						<div class="flex items-center justify-between gap-3 pb-1">
							<h2 class="text-lg font-medium leading-6 text-gray-400" v-html="getUploadTitle"/>
							<InfoQuickViewDialog :title="getUploadInfoTitle" :sub-title="getUploadInfoSubTitle"
																	 :description="getUploadInfoDescription" :visible="isInfoDialogVisible"
																	 @close="isInfoDialogVisible=false"></InfoQuickViewDialog>
							<a @click="isInfoDialogVisible = true"
								 class="cursor-pointer text-sm font-medium leading-6 text-gr-primary3 hover:text-gr-primary2">{{
									$t('assistant.output.upload.file.image.moreInfosHowToUse')
								}}</a>
						</div>
						<p v-if="estimatedCredits > 0" class="pb-2">Estimated Credits: <span
								class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{{ estimatedCredits }}</span>
						</p>
						<UploadInteraction :allowed-types="getUploadAllowedFileTypeList" @on-upload="uploadStarted"
															 @on-upload-end="uploadFinished" @on-upload-error="uploadError"
															 :file-path="uploadedFile && uploadedFile.externalFileUrl ? uploadedFile.externalFileUrl : ''"></UploadInteraction>
						<RadioGroup v-if="isUploadActionListVisible && getAllowedUploadActionListBySubType.length > 0"
												v-model="selectedUploadAction"
												:class="[fileUploadLoading ? 'cursor-not-allowed disabled:opacity-75' : '', 'mt-2']">
							<RadioGroupLabel class="sr-only">{{
									$t('assistant.output.upload.file.image.chooseAnImageAction')
								}}
							</RadioGroupLabel>
							<div class="grid grid-cols-2 gap-3 sm:grid-cols-2">
								<RadioGroupOption as="template" v-for="uploadAction in getAllowedUploadActionListBySubType"
																	:key="uploadAction.subType" :value="uploadAction" :disabled="!uploadAction.visible"
																	v-slot="{ active, checked }">
									<div
											:class="[uploadAction.visible && !fileUploadLoading ? 'cursor-pointer focus:outline-none' : 'cursor-not-allowed opacity-25', active ? 'ring-2 ring-gr-primary ring-offset-2' : '', checked ? 'bg-gr-primary text-white hover:bg-gr-primary-500' : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50', 'flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold uppercase sm:flex-1']">
										<RadioGroupLabel as="span">{{ uploadAction.name }}</RadioGroupLabel>
									</div>
								</RadioGroupOption>
							</div>
						</RadioGroup>
						<!--						<InputNumberField v-model:number="selectedImageUploadData.count" min="1" max="5"></InputNumberField>-->
						<button @click="cancelUploadPrompt(contentSearchItem?.uuid)" type="button" :disabled="fileUploadLoading"
										:class="[fileUploadLoading ? 'cursor-not-allowed disabled:opacity-75' : '', 'mt-2 w-full rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50']">
							{{ $t('modal.cancelButtonText') }}
						</button>
					</div>
				</template>
			</output-card>
		</template>
		<template v-else>
			<output-card v-if="contentSearchItem?.prompt && !contentSearchItem.hasCustomOutput()"
									 :card-reference="'prompt-'+contentSearchItem.uuid" :share-link="contentSearchItem.getSharedLink()"
									 :share-mode="contentSearchItem.getSharedMode()"
									 :card-content="contentSearchItem.prompt" :item-uuid="contentSearchItem.uuid"
									 :is-pinned="contentSearchItem.is_pinned"
									 @pin-item="contentSearchItem.is_pinned != contentSearchItem.is_pinned"
									 :card-interaction="false" :card-info="false" :reverse-side="true">
				<template v-slot:CardContent>
					<DefaultOutputElement :content="contentSearchItem.prompt"></DefaultOutputElement>
				</template>
			</output-card>
			<output-card v-if="contentSearchItem && contentSearchItem.hasCustomOutput()"
									 :card-reference="'prompt-'+contentSearchItem.uuid" :share-link="contentSearchItem.getSharedLink()"
									 :share-mode="contentSearchItem.getSharedMode()"
									 :card-content="contentSearchItem.prompt" :item-uuid="contentSearchItem.uuid"
									 :is-pinned="contentSearchItem.is_pinned"
									 @pin-item="contentSearchItem.is_pinned != contentSearchItem.is_pinned"
									 :card-interaction="false" :card-info="false" :reverse-side="true">
				<template v-slot:CardContent>
					<ImageVariationRequestImageElement
							v-if="contentSearchItem?.subType === GreeveSearchItemSubType.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION"
							:search-item="contentSearchItem"></ImageVariationRequestImageElement>
					<ImageVisionInitialImageElement
							v-else-if="contentSearchItem?.subType === GreeveSearchItemSubType.SEARCH_ITEM_SUBTYPE_VISION"
							:search-item="contentSearchItem"></ImageVisionInitialImageElement>
					<AudioOutputElement
							v-else-if="contentSearchItem?.subType === GreeveSearchItemSubType.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT"
							:search-item="contentSearchItem"></AudioOutputElement>
				</template>
			</output-card>
			<output-card :card-reference="'response-'+contentSearchItem?.uuid" :card-state="contentSearchItem?.state"
									 :share-link="contentSearchItem?.getSharedLink()" :share-mode="contentSearchItem?.getSharedMode()"
									 :search-item="searchItem"
									 :card-content="contentSearchItem?.getContent()" :item-uuid="contentSearchItem?.uuid"
									 :is-pinned="contentSearchItem?.is_pinned"
									 @pin-item="contentSearchItem?.is_pinned != contentSearchItem?.is_pinned"
									 @retry-error-item="retryErrorItem"
									 :card-interaction="true" :card-info="false" :reverse-side="false">
				<template v-slot:CardContent>
					<div v-if="contentSearchItem?.hasError()">
						<DefaultOutputElement :content="contentSearchItem?.getErrorContent()"></DefaultOutputElement>
					</div>
					<div v-else>
						<AudioOutputElement
								v-if="contentSearchItem?.subType === GreeveSearchItemSubType.SEARCH_ITEM_SUBTYPE_TEXT_TO_SPEECH"
								:src="contentSearchItem?.getContent()"></AudioOutputElement>
						<ImageOutputElement
								v-else-if="contentSearchItem?.subType === GreeveSearchItemSubType.SEARCH_ITEM_SUBTYPE_IMAGE"
								:response="contentSearchItem?.getResponse()"></ImageOutputElement>
						<ImageVariationOutputElement
								v-else-if="contentSearchItem?.subType === GreeveSearchItemSubType.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION"
								:search-item="contentSearchItem"></ImageVariationOutputElement>
						<DefaultOutputElement v-else :content="contentSearchItem?.getContent()"></DefaultOutputElement>
					</div>
				</template>
			</output-card>
		</template>
	</div>
</template>

<script lang="ts" setup>
import {computed, defineEmits, defineProps, onMounted, onUnmounted, reactive, ref, watch} from 'vue';
import {AbstractSearchItem} from '@/greeve/search/item/abstract_search_item.type';
import OutputCard from '@/components/assistant/Output/OutputCard.vue';
import DefaultOutputElement from '@/components/assistant/Output/Elements/DefaultOutputElement.vue';
import AudioOutputElement from '@/components/assistant/Output/Elements/AudioOutputElement.vue';
import ImageOutputElement from '@/components/assistant/Output/Elements/ImageOutputElement.vue';
import {GreeveSearchItemSubTypeInterface} from '@/greeve/search/item/search_item.interface';
import {RadioGroup, RadioGroupLabel, RadioGroupOption} from '@headlessui/vue';
import UploadInteraction from '@/components/assistant/Output/Elements/Interaction/UploadInteraction.vue';
import {MediaFileInfo} from '@/greeve/media/media_file_info.type';
import ImageVariationOutputElement from '@/components/assistant/Output/Elements/ImageVariationOutputElement.vue';
import ImageVariationRequestImageElement
	from '@/components/assistant/Output/Elements/ImageVariationRequestImageElement.vue';
import ImageVisionInitialImageElement from '@/components/assistant/Output/Elements/ImageVisionRequestImageElement.vue';
import useTranslation from '@/composable/translation/useTranslation';
import InfoQuickViewDialog from '@/components/modal/InfoQuickViewDialog.vue';
import DefaultSpinner from '@/components/animations/DefaultSpinner.vue';
import useAssistant from '@/composable/greeve/useAssistant';

const props = defineProps({
	searchItem: {
		type: Object as () => AbstractSearchItem,
	},
	isLoading: {
		type: Boolean,
	},
});

const emits = defineEmits([
	'retryErrorItem',
	'cancelUploadPrompt',
	'uploadFinished',
	'updateUploadInteractionType',
	'enableTextInput',
	'disableTextInput']);

const contentSearchItem = ref<AbstractSearchItem | undefined>(props.searchItem);
const fileUploadLoading = ref(false);
const {t, getI18nArray} = useTranslation();
const {getEstimatedCredits} = useAssistant();
const uploadedFile = ref<MediaFileInfo | undefined>(undefined);

const selectedImageUploadData = reactive({
	count: 1,
});

const selectedUploadAction = ref();
const uploadActions =
		[
			// { globalType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE, subType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_EDIT, name: 'Edit Image', visible: true},
			// { globalType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE, subType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_REMOVE_BACKGROUND, name: 'Change Background', visible: true},
			{
				globalType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE,
				subType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION,
				name: t('assistant.output.upload.file.image.variations.button.title'),
				visible: true,
			},
			{
				globalType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE,
				subType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION,
				name: t('assistant.output.upload.file.image.vision.button.title'),
				visible: true,
			},
			{
				globalType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT,
				subType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT,
				name: t('assistant.output.upload.file.speech_to_text.variations.speech_to_text.button.title'),
				visible: true,
			},
		];
const estimatedCredits = ref(0);

const getUploadAction = computed(() => {
	const subType = contentSearchItem.value?.subType;
	if (subType) {
		const action = uploadActions.find(action => action.subType === subType);
		if (action) {
			return action;
		}
		return uploadActions.find(action => action.globalType === subType);
	}
	return undefined;
});

const isUploadActionListVisible = computed(() => {
	switch (contentSearchItem.value?.subType) {
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
			return false;
		default:
			return true;
	}
});

const getAllowedUploadActionListBySubType = computed(() => {
	if (!isUploadActionListVisible.value) {
		return [];
	}
	let filterActions = uploadActions.filter(action => action.globalType === contentSearchItem.value?.subType);
	if (!filterActions || !filterActions.length) {
		const actionBySubType = uploadActions.find(action => action.subType === contentSearchItem.value?.subType);
		if (actionBySubType && actionBySubType.globalType) {
			filterActions = uploadActions.filter(action => action.globalType === actionBySubType.globalType);
		}
	}
	return filterActions;
});

const isInfoDialogVisible = ref(false);

const GreeveSearchItemSubType = {
	SEARCH_ITEM_SUBTYPE_DEFAULT: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_DEFAULT,
	SEARCH_ITEM_SUBTYPE_CHAT: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_CHAT,
	SEARCH_ITEM_SUBTYPE_COMPLETION: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_COMPLETION,
	SEARCH_ITEM_SUBTYPE_IMAGE: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE,
	SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION,
	SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT,
	SEARCH_ITEM_SUBTYPE_TEXT_TO_SPEECH: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_TEXT_TO_SPEECH,
	SEARCH_ITEM_SUBTYPE_VISION: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION,
	SEARCH_ITEM_SUBTYPE_HTML: 'html',
	SEARCH_ITEM_SUBTYPE_RECIPE: 'recipe',
	SEARCH_ITEM_SUBTYPE_STUDY: 'study',
	SEARCH_ITEM_SUBTYPE_IMAGE_GENERATION: 'image_generation',
	SEARCH_ITEM_SUBTYPE_CODE: 'code',
	SEARCH_ITEM_SUBTYPE_BLOG: 'blog',
	SEARCH_ITEM_SUBTYPE_ARTICLE: 'article',
};

const currentLoadingText = ref<string>(''); // Aktueller Text, der angezeigt wird
let intervalId: number | undefined; // Intervall-ID für den Textwechsel

const startLoadingTextCycling = () => {
	if (!contentSearchItem.value?.isInteractionSearchItemUpload()) {
		return '';
	}

	let loadingType = 'default';
	switch (contentSearchItem.value?.subType) {
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION:
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION:
			loadingType = GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE;
			break;
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
			loadingType = GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT;
			break;
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_TEXT_TO_SPEECH:
			loadingType = GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_TEXT_TO_SPEECH;
			break;
	}

	const loadingTexts = getI18nArray(`assistant.output.upload.file.${loadingType}.loading_texts`);

	if (!loadingTexts || loadingTexts.length === 0) {
		currentLoadingText.value = '';
		return;
	}

	let index = 0;
	currentLoadingText.value = loadingTexts[index];

	intervalId = setInterval(() => {
		index = (index + 1) % loadingTexts.length;
		currentLoadingText.value = loadingTexts[index];
	}, 5000);
};

// Intervall beenden
const stopLoadingTextCycling = () => {
	if (intervalId) {
		clearInterval(intervalId);
	}
};

const getUploadTitle = computed(() => {
	if (!contentSearchItem.value?.isInteractionSearchItemUpload()) {
		return '';
	}

	switch (contentSearchItem.value?.subType) {
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION:
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION:
			return t('assistant.output.upload.file.image.title');
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
			return t('assistant.output.upload.file.speech_to_text.title');
		default:
			return '';
	}
});

const getUploadInfoTitle = computed(() => {
	if (!contentSearchItem.value?.isInteractionSearchItemUpload()) {
		return '';
	}

	switch (contentSearchItem.value?.subType) {
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION:
			return t('assistant.output.upload.file.image.variations.info.title');
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION:
			return t('assistant.output.upload.file.image.vision.info.title');
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
			return t('assistant.output.upload.file.speech_to_text.info.title');
		default:
			return '';
	}
});

const getUploadInfoSubTitle = computed(() => {
	if (!contentSearchItem.value?.isInteractionSearchItemUpload()) {
		return '';
	}

	switch (contentSearchItem.value?.subType) {
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION:
			return t('assistant.output.upload.file.image.variations.info.subTitle');
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION:
			return t('assistant.output.upload.file.image.vision.info.subTitle');
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
			return t('assistant.output.upload.file.speech_to_text.info.subTitle');
		default:
			return '';
	}
});

const getUploadLoadingDescription = computed(() => {
	if (!contentSearchItem.value?.isInteractionSearchItemUpload()) {
		return '';
	}

	switch (contentSearchItem.value?.subType) {
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION:
			return t('assistant.output.upload.file.image.variations.loading.description');
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION:
			return t('assistant.output.upload.file.image.vision.loading.description');
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
			return t('assistant.output.upload.file.speech_to_text.loading.description');
		default:
			return '';
	}
});

const getUploadInfoDescription = computed(() => {
	if (!contentSearchItem.value?.isInteractionSearchItemUpload()) {
		return '';
	}

	switch (contentSearchItem.value?.subType) {
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION:
			return t('assistant.output.upload.file.image.variations.info.description');
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION:
			return t('assistant.output.upload.file.image.vision.info.description');
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
			return t('assistant.output.upload.file.speech_to_text.info.description');
		default:
			return '';
	}
});

const getUploadAllowedFileTypeList = computed(() => {
	if (!contentSearchItem.value?.isInteractionSearchItemUpload()) {
		return [];
	}
	switch (contentSearchItem.value?.subType) {
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE:
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION:
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION:
			return ['image/jpeg', 'image/png', 'image/heic', 'image/heif', 'image/webp'];
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
			return ['audio/mpeg', 'audio/wav', 'audio/ogg', 'audio/mp4', 'audio/aac', 'audio/x-m4a', 'video/mp4'];
		default:
			return [];
	}
});

function retryErrorItem(searchItemUuid: string, subType: GreeveSearchItemSubTypeInterface) {
	emits('retryErrorItem', searchItemUuid, subType);
}

function cancelUploadPrompt(searchItemUuid: string | undefined) {
	estimatedCredits.value = 0;
	emits('cancelUploadPrompt', searchItemUuid);
	emits('enableTextInput');
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function uploadStarted(file: Blob) {
	startLoadingTextCycling();
	estimatedCredits.value = 0;
	fileUploadLoading.value = true;
	emits('updateUploadInteractionType', contentSearchItem.value?.uuid, selectedUploadAction.value?.subType);
	emits('disableTextInput');
}

async function calcualteEestimatedCredits(fileInfo: MediaFileInfo) {
	getEstimatedCredits(undefined, undefined, fileInfo.externalFileUrl).then((credits) => {
		if (!credits) {
			return;
		}
		estimatedCredits.value = credits.credits;
	});
}

function uploadFinished(fileInfo: MediaFileInfo) {
	stopLoadingTextCycling();
	calcualteEestimatedCredits(fileInfo);
	fileUploadLoading.value = false;
	uploadedFile.value = fileInfo;
	emits('uploadFinished', contentSearchItem.value?.uuid, fileInfo);
	emits('enableTextInput');
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function uploadError(e: any) {
	stopLoadingTextCycling();
	estimatedCredits.value = 0;
	fileUploadLoading.value = false;
	emits('enableTextInput');
}

watch(() => props.searchItem, (newItem) => {
	if (newItem && newItem !== contentSearchItem.value) {
		contentSearchItem.value = newItem;
	}
});

watch(() => selectedUploadAction.value, (newSelectedUploadAction, oldValue) => {
	if ((!oldValue || oldValue.subType !== newSelectedUploadAction.subType) && newSelectedUploadAction.subType) {
		emits('updateUploadInteractionType', contentSearchItem.value?.uuid, newSelectedUploadAction.subType);
	}
});

onMounted(() => {
	startLoadingTextCycling();
	if (contentSearchItem.value && contentSearchItem.value?.isInteractionSearchItemUpload()) {
		if (!selectedUploadAction.value) {
			selectedUploadAction.value = getUploadAction.value;
		}

		emits('updateUploadInteractionType', contentSearchItem.value?.uuid, selectedUploadAction.value?.subType);
	}
});

onUnmounted(() => {
	stopLoadingTextCycling();
});

</script>
<style lang="scss" scoped>
</style>
