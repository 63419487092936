<template>
	<div class=" space-x-3 rounded-3xl pb-1 md:pb-6 px-2 md:px-4" :id="cardReference">
		<div class="flex items-end mt-3" :class="[reverseSide ? 'flex-row-reverse' : '']">
			<div v-if="!reverseSide"
					 class="flex-shrink-0 h-8 w-8 md:h-10 md:w-10 rounded-full bg-gray-200 dark:bg-gr-darkest mb-10"><img
					class="p-2" src="assets/icon.png"></div>
			<div v-else>
				<UserCircleIcon class="flex-shrink-0 text-gray-300 dark:text-gray-400 h-8 w-8 md:h-10 md:w-10 rounded-full overflow-hidden mb-2.5 md:mb-3" aria-hidden="true"></UserCircleIcon>
			</div>
			<slot name="CardContainer">
				<div v-show="cardInfo" class="grid text-gray-700 grid-cols-2">
					<div class="col-1 text-left text-md truncate">{{ cardTitle }}</div>
					<div class="col-1 text-right text-md">{{ cardDate }}</div>
				</div>
				<div :class="['max-w-[88%] sm:max-w-xl lg:max-w-3xl xl:max-w-4xl', cardState === 'error' ? 'min-w-[60%]' : '', reverseSide ? 'mr-1 md:mr-3' : 'ml-2 md:ml-4']" ref="contentDiv">
					<div v-if="((!cardContentNew || cardContentNew.length === 0) && cardState === 'new')" :class="['rounded-2xl py-1 px-2 md:p-3', reverseSide ? 'bg-gr-primary text-white rounded-br-sm' : 'bg-gray-200 text-gray-700 rounded-bl-sm']">
						<div class="flex items-center justify-center py-2">
							<div class="flex space-x-2 ">
								<span class="w-2 h-2 bg-gray-500 rounded-full animate-loader"></span>
								<span class="w-2 h-2 bg-gray-500 rounded-full animate-loader animation-delay-200"></span>
								<span class="w-2 h-2 bg-gray-500 rounded-full animate-loader animation-delay-400"></span>
							</div>
						</div>
					</div>
<!--					TODO FIX -->
<!--					INTERACTION - bg-gradient-to-r from-red-500 to-orange-500 -->
					<div v-else-if="cardState === 'error'"
							 :class="['rounded-2xl py-1 px-2 md:p-3 border-2 border-rose-600 bg-red-600 bg-opacity-95', reverseSide ? 'rounded-br-sm  text-gray-200 dark:text-gray-200' : 'text-gray-200 dark:text-gray-200 rounded-bl-sm']">
						<div class="flex">
							<span class="inline-flex items-center rounded-full bg-red-50 px-1.5 py-0.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 mr-2">Error</span>
							<slot name="CardContent"></slot>
						</div>
					</div>
					<div v-else-if="cardState === 'in_progress'"
							 :class="['rounded-2xl py-1 px-2 md:p-3', reverseSide ? 'bg-gr-primary text-white rounded-br-sm' : 'bg-gray-200 text-gray-700 rounded-bl-sm']">
						<div class="flex items-center flex-col justify-center py-2">
<!--							<span class="inline-flex items-center rounded-full bg-red-50 px-1.5 py-0.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-orange-600/10 mr-2" v-html="getInProgressMessage()"/>-->
							<div class="flex space-x-2 my-1">
								<span class="w-2 h-2 bg-gray-500 rounded-full animate-loader"></span>
								<span class="w-2 h-2 bg-gray-500 rounded-full animate-loader animation-delay-200"></span>
								<span class="w-2 h-2 bg-gray-500 rounded-full animate-loader animation-delay-400"></span>
							</div>
<!--							TODO check and add new cool loading spinner-->
							<span class="inline-flex items-center my-2 mx-2 rounded-full bg-red-50 px-2 py-2 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/10" v-html="getInProgressMessage()"/>
							<slot name="CardContent"></slot>
						</div>
					</div>
					<div v-else
							 :class="['rounded-2xl py-1 px-2 md:p-3', reverseSide ? 'bg-gray-300 dark:bg-gr-dark rounded-br-sm  text-gray-700 dark:text-gray-400' : 'bg-gray-200 dark:bg-gr-darker text-gray-700 dark:text-gray-400 rounded-bl-sm']">
						<slot name="CardContent"></slot>
					</div>
					<div class="mt-3 mb-1" :class="[reverseSide ? 'items-start' : '']">
						<div v-show="cardInteraction" class="">
							<div class="-mx-2 text-gray-400 -mt-3 md:-mt-1.5 -my-1.5">
								<div class="flex space-x-1 text-left" :class="[reverseSide ? 'flex-row' : 'flex-row-reverse']">
									<template v-if="cardState === 'error'">
										<div>
											<button type="button" @click="retryErrorItem" class="rounded-md px-2 py-1.5 text-sm font-medium dark:text-gray-400 focus:outline-none hover:text-gray-600">
												<ArrowPathIcon class="w-6 h-6 mx-auto"></ArrowPathIcon>
											</button>
										</div>
										<Menu as="div" class="relative text-left self-center">
											<div class="self-center">
												<MenuButton class="group flex  justify-center font-medium text-gray-400 hover:text-gray-600">
													<ion-icon class="px-0 text-2xl" :icon="icons.ellipsisHorizontalCircleOutline"></ion-icon>
												</MenuButton>
											</div>
											<transition enter-active-class="transition ease-out duration-100"
																	enter-from-class="transform opacity-0 scale-95"
																	enter-to-class="transform opacity-100 scale-100"
																	leave-active-class="transition ease-in duration-75"
																	leave-from-class="transform opacity-100 scale-100"
																	leave-to-class="transform opacity-0 scale-95">
												<MenuItems
														class="overflow-hidden absolute right-0 top-0 bottom-auto z-10 w-20 -mt-10 origin-top-right rounded-xl bg-white dark:bg-gr-dark shadow-xl focus:outline-none backdrop-filter backdrop-blur-xl bg-rgba-white-90">
													<div>
														<MenuItem>
															<div class="col-1">
																<RatingAction @rate="rateSearchResult" :rated-result="searchResultRated" :rated="resultRated"></RatingAction>
															</div>
														</MenuItem>
													</div>
												</MenuItems>
											</transition>
										</Menu>
									</template>
									<template v-else>
										<ShareAction :reference="cardReference" :description="cardContentNew" :share-link="shareLink" :share-mode="shareMode" :search-item="searchItem"></ShareAction>
										<InteractionAction :reference="cardReference" :description="cardContentNew" :share-link="shareLink" :share-mode="shareMode" :is-book-marked="isPinned" @book-mark="pinSearchItem"></InteractionAction>
										<Menu as="div" class="relative text-left self-center">
											<div class="self-center">
												<MenuButton class="group flex  justify-center font-medium text-gray-400 hover:text-gray-600">
													<ion-icon class="px-0 text-2xl" :icon="icons.ellipsisHorizontalCircleOutline"></ion-icon>
												</MenuButton>
											</div>
											<transition enter-active-class="transition ease-out duration-100"
																	enter-from-class="transform opacity-0 scale-95"
																	enter-to-class="transform opacity-100 scale-100"
																	leave-active-class="transition ease-in duration-75"
																	leave-from-class="transform opacity-100 scale-100"
																	leave-to-class="transform opacity-0 scale-95">
												<MenuItems
														class="overflow-hidden absolute right-0 top-0 bottom-auto z-10 w-20 -mt-10 origin-top-right rounded-xl bg-white dark:bg-gr-dark shadow-xl focus:outline-none backdrop-filter backdrop-blur-xl bg-rgba-white-90">
													<div>
														<!--<MenuItem>
															<voice-read-action
																	:text-to-read="cardContent"></voice-read-action>
														</MenuItem>
														<MenuItem>
															<button @click="toggleCategories" type="button">
																<ion-icon class="px-2 text-xl" :icon="icons.reloadOutline"></ion-icon>
															</button>
														</MenuItem> -->
														<MenuItem>
															<div class="col-1">
																<RatingAction @rate="rateSearchResult" :rated-result="searchResultRated" :rated="resultRated"></RatingAction>
															</div>
														</MenuItem>
													</div>
												</MenuItems>
											</transition>
										</Menu>
									</template>
								</div>
								<Transition>
									<category-input v-show="categoryVisibleState" @clickCategory="searchGreeveByType"
																	:search-result="searchItem" class="col-span-3"></category-input>
								</Transition>
							</div>
						</div>
					</div>
				</div>
			</slot>

		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref, Transition, watch} from 'vue';
import ShareAction from '@/components/actions/ShareAction.vue';
import RatingAction from '@/components/actions/RatingAction.vue';
import useToastMessage from '@/composable/core/useToastMessage';
import useTranslation from '@/composable/translation/useTranslation';
import CategoryInput from '@/components/inputs/CategoryInput.vue';
import {createGesture, IonIcon} from '@ionic/vue';
import * as icons from 'ionicons/icons';
import InteractionAction from '@/components/actions/InteractionAction.vue';
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue';
import {GreeveApiAssistant} from '@/greeve/api/assistant';
import useAssistant from '@/composable/greeve/useAssistant';
import {ArrowPathIcon, UserCircleIcon} from '@heroicons/vue/20/solid';
import {AbstractSearchItem, ChatType} from '@/greeve/search/item/abstract_search_item.type';

export default defineComponent({
	name: 'OutputCard',
	components: {
		UserCircleIcon,
		MenuItems,
		MenuItem,
		MenuButton,
		Menu, InteractionAction, CategoryInput, IonIcon, RatingAction, ShareAction, Transition, ArrowPathIcon},
	props: {
		reverseSide: {
			type: Boolean,
			default: false
		},
		cardDate: {
			type: String,
			default: '00.00.00'
		},
		searchItem: {
			type: Object as () => AbstractSearchItem,
			default: null,
		},
		cardTitle: {
			type: String,
			default: 'Search Input'
		},
		cardContent: {
			type: String,
			default: ''
		},
		cardReference: {
			type: String,
			default: ''
		},
		shareLink: {
			type: String,
			default: '',
		},
		shareMode: {
			type: String as () => ChatType,
			default: () => ChatType.CHAT,
		},
		itemUuid: {
			type: String,
			default: ''
		},
		isPinned: {
			type: Boolean,
			default: false
		},
		cardState: {
			type: String,
			default: ''
		},
		cardInfo: {
			type: Boolean,
			default: true
		},
		cardInteraction: {
			type: Boolean,
			default: true
		},
	},
	emits: ['searchGreeveByType', 'pinItem', 'retryErrorItem'],
	setup(props, {emit}) {
		const {t} = useTranslation();
		const searchResultRated = ref(false);
		const resultRated = ref(false);
		const categoryVisibleState = ref(false);
		const {openToast} = useToastMessage();
		const cardContentNew = ref<string>(props.cardContent);
		const {pinItem} = useAssistant();

		function toggleCategories() {
			categoryVisibleState.value = !categoryVisibleState.value;
		}

		function searchGreeveByType(type: string) {
			emit('searchGreeveByType', type);
		}

		function retryErrorItem() {
			emit('retryErrorItem', props.itemUuid, props.searchItem.subType);
		}

		async function pinSearchItem(pinned: boolean) {
			try {
				pinItem(props.itemUuid, pinned);
			} catch (error) {
				let errorMessage = t("toast,errorRating");
				await openToast(errorMessage, 'danger', 'top', true, 12000, undefined, true);
				console.error(error);
			}
		}

		async function rateSearchResult(rate: boolean) {
			try {
				let rateType = 'good';
				if (!rate) {
					rateType = 'bad';
				}
				searchResultRated.value = rateType === 'good';
				resultRated.value = true;
				GreeveApiAssistant.voteSearchResult(props.itemUuid, rateType).then((result) => {
					if (result) {
						searchResultRated.value = rateType === 'good';
						resultRated.value = true;
					} else {
						resultRated.value = false;
					}
				});
			} catch (error: Error | any) {
				let errorMessage = t("toast,errorRating");
				await openToast(errorMessage, 'danger', 'top', true, 12000, undefined, true);
				console.error(error);
			}
		}

		const contentDiv = ref<HTMLElement|any>(null);
		let pressTimer: any | null = null;
		let touchMoved = false;

		const onStartPress = () => {
			pressTimer = setTimeout(() => {
				if (!touchMoved) {
					const range = document.createRange();
					range.selectNodeContents(contentDiv.value);
					const selection: any = window.getSelection();
					selection.removeAllRanges();
					selection.addRange(range);
				}
			}, 1300);
		};

		const onEndPress = () => {
			if (pressTimer !== null) {
				clearTimeout(pressTimer);
				pressTimer = null;
			}
			touchMoved = false;
		};

		const getInProgressMessage = () => {
			if (!props.searchItem.isInProgress()) {
				return '';
			}
			return props.searchItem.getInProgressMessage();
		}

		watch(() => props.cardContent, (newContent) => {
			if (newContent && newContent.length > 0) {
				cardContentNew.value = newContent;
			}
		});

		onMounted(() => {
			const gesture = createGesture({
				el: contentDiv.value,
				threshold: 0,
				gestureName: 'press',
				onStart: () => onStartPress(),
				onEnd: () => onEndPress(),
				disableScroll: true,
				onMove: () => {
					touchMoved = true;
					if (pressTimer !== null) {
						clearTimeout(pressTimer);
						pressTimer = null;
					}
				},
			});

			gesture.enable();
		});

		return {
			icons,
			t,
			searchResultRated,
			rateSearchResult,
			toggleCategories,
			categoryVisibleState,
			searchGreeveByType,
			cardContentNew,
			pinSearchItem,
			resultRated,
			contentDiv,
			retryErrorItem,
			getInProgressMessage
		}
	}
});

</script>
<style lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.bg-rgba-white-90 {
	background-color: rgba(255, 255, 255, 0.90);
}
</style>
