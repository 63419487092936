import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, unref as _unref, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Transition as _Transition, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex sm:mt-2 md:mt-0 flex-col h-full pb-2 sm:pb-0" }
const _hoisted_2 = { class: "flex flex-col flex-grow nav rounded-3xl overflow-hidden" }
const _hoisted_3 = {
  key: 0,
  class: "block sm:hidden"
}
const _hoisted_4 = { class: "flex items-center justify-between px-6 py-1.5 sm:px-3.5 bg-gray-100 dark:bg-gray-800 bg-opacity-55 dark:bg-opacity-60" }
const _hoisted_5 = { class: "flex items-center gap-2.5" }
const _hoisted_6 = { class: "block lg:hidden rounded-2xl backdrop-filter backdrop-blur-xl" }
const _hoisted_7 = { class: "sticky top-0 z-40 flex items-center text-gr-dark dark:text-white" }
const _hoisted_8 = { class: "flex" }
const _hoisted_9 = { class: "flex-1 text-sm text-center leading-6 text-gr-dark dark:text-white" }
const _hoisted_10 = { href: "#" }
const _hoisted_11 = { class: "font-semibold" }
const _hoisted_12 = { class: "flex gap-2.5 items-center" }
const _hoisted_13 = { class: "relative" }
const _hoisted_14 = {
  key: 0,
  class: "flex items-center justify-center absolute w-full -mt-1 px-14 z-10"
}
const _hoisted_15 = { class: "inline-block bg-gray-500 text-white px-3 py-1 text-sm font-semibold rounded-full truncate" }
const _hoisted_16 = { class: "hidden sm:block flex text-gray-400 justify-between mx-4 mt-2 py-2 sm:py-4 px-2" }
const _hoisted_17 = { class: "flex gap-2.5 lg:hidden block" }
const _hoisted_18 = { class: "block lg:hidden rounded-2xl backdrop-filter backdrop-blur-xl" }
const _hoisted_19 = { class: "sticky top-0 z-40 flex items-center justify-between lg:hidden" }
const _hoisted_20 = { class: "flex" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { class: "flex text-gray-400 gap-2.5" }
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = {
  key: 2,
  class: "overflow-auto h-full"
}
const _hoisted_25 = { class: "pt-2 bg-gray-100 bg-opacity-55 dark:bg-gray-800 dark:bg-opacity-20" }
const _hoisted_26 = { class: "z-10 sticky bottom-0" }
const _hoisted_27 = {
  key: 2,
  class: "flex gap-2.5"
}
const _hoisted_28 = ["disabled"]
const _hoisted_29 = ["disabled"]

import {computed, onBeforeUnmount, onMounted, ref, watch} from 'vue';
import {SearchResultList} from '@/greeve/search/search_result_list.type';
import {SearchResult} from '@/greeve/search/search_result.type';
import useToastMessage from '@/composable/core/useToastMessage';
import useSystem from '@/composable/core/useSystem';
import useTranslation from '@/composable/translation/useTranslation';
import LoadingLineAnimation from '@/components/animations/LoadingLineAnimation.vue';
import {RouteLocationNormalizedLoaded, useRoute} from 'vue-router';
import TextInput, {InputMode} from '@/components/inputs/TextInput.vue';
import {Bars3Icon, BookmarkIcon} from '@heroicons/vue/24/outline';
import LoadingLineBlurAnimation from '@/components/animations/LoadingLineBlurAnimation.vue';
import {Base64} from '@/greeve/core/encoding';
import useAssistant from '@/composable/greeve/useAssistant';
import {SearchGroup} from '@/greeve/search/group/search_group.type';
import useAuth from '@/composable/auth/useAuth';
import {SearchItemAuthorization} from '@/greeve/search/authorization/search_item_authorization.type';
import useAssistantFactory from '@/composable/greeve/useAssistantFactory';
import {
	GreeveSearchItemStateInterface,
	GreeveSearchItemSubTypeInterface,
} from '@/greeve/search/item/search_item.interface';
import {SearchGroupList} from '@/greeve/search/group/search_group_list.type';
import {IonContent, IonRefresher, IonRefresherContent} from '@ionic/vue';
import OutputTag from '@/components/outputs/OutputTag.vue';
import useCustomStore from '@/composable/custom/useCustomStore';
import InfoCard from '@/components/modal/InfoCard.vue';
import OutputContainer from '@/components/assistant/Output/OutputContainer.vue';
import PaywallOverlay from '@/components/modal/PaywallOverlay.vue';
import ActionBanner from '@/components/modal/ActionBanner.vue';
import useUser from '@/composable/greeve/useUser';
import {QuotaType} from '@/greeve/user/quota/quota.interface';
import {AudioVoice} from '@/greeve/search/item/type/subtype/search_item_text_to_speech.type';
import {ImageSize} from '@/greeve/search/item/type/subtype/search_item_image.type';
import {MediaFileInfo} from '@/greeve/media/media_file_info.type';
import {SearchItemUploadInteraction} from '@/greeve/search/item/type/search_item_upload_interaction.type';
import {AbstractSearchItem, ChatType} from '@/greeve/search/item/abstract_search_item.type';
import {SearchItemTranscribeAudio} from '@/greeve/search/item/type/subtype/search_item_transcribe_audio.type';

const buffer = 30;

	
export default /*@__PURE__*/_defineComponent({
  __name: 'ChatPage',
  props: {
	groupChanged: {
		type: Number,
	},
},
  emits: ['openSidebar'],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const props = __props;

	const {isAuthenticated} = useAuth();
	const {setMobileMenuSidebar, isMobileDevice} = useCustomStore();
	const {t} = useTranslation();
	const {getQuotas, initQuotas} = useUser();
	const isLoading = ref(true);
	const isTextInputDisabled = ref(false);
	const searchValue = ref('');
	const searchResult: SearchResult | any = ref('');
	const searchResultList: SearchResultList | any = ref(new SearchResultList([]));
	const {openToast} = useToastMessage();
	const {scrollToElement, scrollToTop} = useSystem();
	const filterOnlyPinnedItems = ref(false);
	const lowCreditsRedirectLink = ref('');
	const hasLowCredits = ref(false);
	const inputMode = ref<InputMode>(InputMode.DEFAULT);

	const route = useRoute();
	const groupUuidByUrl = ref('');
	const groupReferenceByUrl = ref('');
	const {
		hasEnoughQuota,
		createAuthorizationItem,
		createEmptySearchItem_by_AuthorizationItem,
		createEmptyUploadInteractionItem,
		createEmptySearchItem,
		createImage,
		createSpeechByText,
		getAssistantStreamApi,
		addSearchItem,
		updateSearchItemResponse,
		updateSearchItemState,
		updateSearchItemUploadInteractionMediaFileInfo,
		updateSearchItemSubType,
		updateSearchItemDefaultByItem,
		retryItem,
		deleteSearchItem,
		getGroupByUuid,
		getItemByUuid,
		searchGroups,
		initSearchItems_by_Group,
		initSearchGroup_Uuid_Reference,
		createImageVariation,
		createSpeechToText,
		editImage,
		generateGroupName,
		initSearchItemByUuids,
	} = useAssistant();

	const selectedGroup = ref<SearchGroup | undefined>();
	const selectedChatType = ref<ChatType>(ChatType.CHAT);
	const hdImage = ref(false);
	const imageSize = ref<ImageSize|undefined>();
	const audioVoice = ref<AudioVoice|undefined>();
	const uploadInteractionItem = ref<SearchItemUploadInteraction|undefined>();

	const scrollToResponse = ref(false);
	const isBeforeEndEventTriggered = ref(false);
	const dataCounter = ref(0);
	const accumulatedData = ref<string[]>([]);
	const onEventTimerId = ref();

	const autoCheckPendingItemsIntervalId = ref();
	const autoCheckPendingItemsIntervalCount = ref(0);

	const isOnlySendButtonVisible = computed(() => {
		const allowedTypes = [GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION, GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT];
		return (uploadInteractionItem.value && allowedTypes.includes(uploadInteractionItem.value?.subType));
	});

	const hasUploadInteractionValidFile = computed(() => {
		return (uploadInteractionItem.value && uploadInteractionItem.value?.mediaFileInfo);
	});

	const getUploadInteractionSubTypeForTranslation = computed(() => {
		switch (uploadInteractionItem.value?.subType) {
			case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION:
				return GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION;
			case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
				return GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT;
			default:
				return 'default';
		}
	});

	function handleFileDrop(file: File) {
		console.log("File dropped:", file);
		processFile(file); // Deine Logik zur Verarbeitung der Datei
	}

	function handleFilePaste(file: File) {
		console.log("File pasted:", file);
		processFile(file); // Deine Logik zur Verarbeitung der Datei
	}

	function processFile(file: File) {
		// Implementiere hier deine Logik zur Verarbeitung der Datei
	}

	function clearTextInput() {
		searchValue.value = '';
	}

	function resetSearch() {
		searchValue.value = '';
		try {
			initGroup(true, true);
		} catch (error) {
			console.error(error);
		}
	}

	function handleRefresh(event: CustomEvent | any) {
		resetSearch();
		setTimeout(() => {
			event.target.complete();
		}, 600);
	}

	function setSelectedInputType(type: ChatType, size: ImageSize|undefined = undefined, hd = false, voice: AudioVoice|undefined = undefined) {
		selectedChatType.value = type;
		imageSize.value = size;
		hdImage.value = hd;
		audioVoice.value = voice;
	}

	async function createImage_by_Prompt(
			searchGroupId: number, searchGroupUuid: string, prompt: string, searchGroupReference: string | null = null) {
		const emptyImagSearchItem = createEmptySearchItem(searchGroupId, prompt,
				GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE);
		addSearchItem(emptyImagSearchItem);
		//TODO add model
		return createImage(prompt, searchGroupUuid, searchGroupReference, false, imageSize.value, hdImage.value).then((searchItemImage) => {
			updateSearchItemDefaultByItem(emptyImagSearchItem, searchItemImage);
			updateGroupNameIfEmpty(prompt, GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE);
			scrollToElement('response-' + searchItemImage.uuid, 40, 500);
		});
	}

	async function createAssistantImageVariation(mediaFileInfo: MediaFileInfo, searchGroupId: number, searchGroupUuid: string, prompt: string, searchGroupReference: string | null = null) {
		// const emptyImagSearchItem = createEmptySearchItem(searchGroupId, prompt, GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION);
		// addSearchItem(emptyImagSearchItem);
		const interactionSearchItem = uploadInteractionItem.value;
		if (!interactionSearchItem) {
			throw new Error('Invalid Call!');
		}
		return createImageVariation(mediaFileInfo, searchGroupUuid, searchGroupReference, false, imageSize.value, hdImage.value).then((searchItemImage) => {
			updateSearchItemDefaultByItem(interactionSearchItem, searchItemImage);
			// updateGroupNameIfEmpty(prompt);
			scrollToElement('response-' + searchItemImage.uuid, 40, 500);
		});
	}

	async function createAssistantSpeechToText(mediaFileInfo: MediaFileInfo, searchGroupId: number, searchGroupUuid: string, prompt: string|null = null, searchGroupReference: string | null = null) {
		const interactionSearchItem = uploadInteractionItem.value;
		if (!interactionSearchItem) {
			throw new Error('Invalid Call!');
		}
		return createSpeechToText(mediaFileInfo, searchGroupUuid, searchGroupReference, false, true).then((searchItem) => {
			updateSearchItemDefaultByItem(interactionSearchItem, searchItem);
			let updateGroupPrompt: string|null|undefined = prompt;
			if (searchItem.subType === GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT) {
				const transcribeAudioItem = (searchItem as SearchItemTranscribeAudio);
				updateGroupPrompt = transcribeAudioItem.getRawText(500);
			}
			if (searchItem.isInProgress()) {
				registerChatBackgroundRunner()
			} else {
				if (!updateGroupPrompt) {
					updateGroupPrompt = undefined;
				}
				updateGroupNameIfEmpty(updateGroupPrompt, GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT);
			}
			scrollToElement('response-' + searchItem.uuid, 40, 500);
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	async function editAssistantImage(mediaFileInfo: MediaFileInfo, prompt: string, negativeText: string|null, searchGroupId: number, searchGroupUuid: string, searchGroupReference: string | null = null) {
		// const emptyImagSearchItem = createEmptySearchItem(searchGroupId, prompt, GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION);
		// addSearchItem(emptyImagSearchItem);
		const interactionSearchItem = uploadInteractionItem.value;
		if (!interactionSearchItem) {
			throw new Error('Invalid Call!');
		}
		return editImage(mediaFileInfo,prompt, negativeText, searchGroupUuid, searchGroupReference, false, imageSize.value, hdImage.value).then((searchItemImage) => {
			updateSearchItemDefaultByItem(interactionSearchItem, searchItemImage);
			scrollToElement('response-' + searchItemImage.uuid, 40, 500);
		});
	}

	async function createSpeech_by_Prompt(
			searchGroupId: number, searchGroupUuid: string, prompt: string, searchGroupReference: string | null = null) {
		const emptyTextToSpeechSearchItem = createEmptySearchItem(searchGroupId, prompt,
				GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_TEXT_TO_SPEECH);
		addSearchItem(emptyTextToSpeechSearchItem);
		return createSpeechByText(prompt, searchGroupUuid, searchGroupReference, false, audioVoice.value).then((searchItemTextToSpeech) => {
			updateSearchItemDefaultByItem(emptyTextToSpeechSearchItem, searchItemTextToSpeech);
			scrollToElement('response-' + searchItemTextToSpeech.uuid, 0, 500);
		});
	}

	function getGreeveSubType_by_Type(type: ChatType): GreeveSearchItemSubTypeInterface {
		switch (type) {
			case ChatType.CHAT:
				return GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_CHAT;
			case ChatType.IMAGE:
				return GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE;
			case ChatType.TEXT_TO_SPEECH:
				return GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_TEXT_TO_SPEECH;
			case ChatType.TRANSCRIBE:
				return GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT;
			default:
				throw new Error(`Invalid type ${type}`);
		}
	}

	async function addNewUploadPrompt(subType: GreeveSearchItemSubTypeInterface) {
		const groupId = selectedGroup.value?.id;
		if (!groupId) {
			openToast(t('toast.errorDefault'), 'danger', 'top', true, 12000, undefined, true);
			return;
		}
		inputMode.value = InputMode.UPLOAD;
		uploadInteractionItem.value = createEmptyUploadInteractionItem(groupId, '', subType);
		if (!uploadInteractionItem.value) {
			openToast(t('toast.errorDefault'), 'danger', 'top', true, 12000, undefined, true);
			return;
		}
		addSearchItem(uploadInteractionItem.value);
		scrollToElement('prompt-' + uploadInteractionItem.value?.uuid, 0, 100, 'end');
	}

	async function cancelCurrentUploadPrompt()
	{
		inputMode.value = InputMode.DEFAULT;
		if (uploadInteractionItem.value) {
			deleteSearchItem(uploadInteractionItem.value);
			uploadInteractionItem.value = undefined;
		}
		isTextInputDisabled.value = false;
	}

	async function cancelNewUploadPrompt(itemUuid: string) {
		inputMode.value = InputMode.DEFAULT;
		const uploadItem = getItemByUuid(itemUuid);
		if (uploadItem) {
			deleteSearchItem(uploadItem);
			uploadInteractionItem.value = undefined;
		}
		isTextInputDisabled.value = false;
	}

	async function uploadFinished(itemUuid: string|undefined, mediaFileInfo: MediaFileInfo) {
		if (!itemUuid) {
			return;
		}
		isTextInputDisabled.value = false;
		if (uploadInteractionItem.value?.uuid === itemUuid) {
			updateSearchItemUploadInteractionMediaFileInfo(uploadInteractionItem.value, mediaFileInfo);
		}
	}

	async function updateInteractionType(itemUuid: string|undefined, subType: GreeveSearchItemSubTypeInterface) {
		if (!itemUuid) {
			return;
		}
		isTextInputDisabled.value = false;
		if (uploadInteractionItem.value?.uuid === itemUuid && subType && uploadInteractionItem.value?.subType !== subType) {
			updateSearchItemSubType(uploadInteractionItem.value, subType);
		}
	}

	function streamOnEvent(searchItemNew: AbstractSearchItem) {
		return async (data: string) => {
			if (isBeforeEndEventTriggered.value) {
				return;
			}
			if (!scrollToResponse.value && data && data.length > 0) {
				scrollToResponse.value = true;
				updateSearchItemResponse(searchItemNew, data).then((result) => {
					if (result) {
						searchItemNew = result;
					}
				});
				setTimeout(() => {
					scrollToElement('response-' + searchItemNew.uuid, 0, 500);
				}, 50);
			} else {
				dataCounter.value++;
				// Accumulate the data
				accumulatedData.value.push(data);
				// Check if the counter reaches the threshold (3-4 entries)
				if (!onEventTimerId.value && accumulatedData.value && dataCounter.value >= 3 && ![GreeveSearchItemStateInterface.SEARCH_ITEM_STATE_DONE, GreeveSearchItemStateInterface.SEARCH_ITEM_STATE_ERROR].includes(searchItemNew.state)) {
					onEventTimerId.value = setTimeout(() => {
						const accumulatedDataString = accumulatedData.value.join('');
						updateSearchItemResponse(searchItemNew, accumulatedDataString).then((result) => {
							if (result) {
								searchItemNew = result;
							}
						});
						// Reset the counter and accumulated data array
						dataCounter.value = 0;
						accumulatedData.value = [];
						clearTimeout(onEventTimerId.value);
						onEventTimerId.value = undefined;
					}, 50);
					// Process accumulated data
				}
			}
			if (searchItemNew.state !== GreeveSearchItemStateInterface.SEARCH_ITEM_STATE_IN_PROGRESS &&
					searchItemNew.state !== GreeveSearchItemStateInterface.SEARCH_ITEM_STATE_DONE) {
				updateSearchItemState(searchItemNew, GreeveSearchItemStateInterface.SEARCH_ITEM_STATE_IN_PROGRESS);
			}
		};
	}

	function streamOnInteractionRequired() {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		return async (data: string) => {
			//TODO
		};
	}

	function streamOnBeforeEndEvent(searchItemNew: AbstractSearchItem) {
		return (data: string) => {
			if (data) {
				isBeforeEndEventTriggered.value = true;
				dataCounter.value = 0;
				accumulatedData.value = [];
				const itemData = JSON.parse(data);
				const resultItem = useAssistantFactory().getSearchItemFactory().createSearchItemByResponse(itemData);
				updateSearchItemDefaultByItem(searchItemNew, resultItem);
				scrollToElement('response-' + resultItem.uuid, 0, 500);
			}
		};
	}

	function streamOnError(searchItemNew: AbstractSearchItem, timeoutId: number) {
		return (data: string) => {
			console.error('ERROR: ' + data);
			updateSearchItemState(searchItemNew, GreeveSearchItemStateInterface.SEARCH_ITEM_STATE_ERROR);
			updateSearchItemResponse(searchItemNew, data);
			isLoading.value = false;
			clearTimeout(timeoutId);
			openToast(t('toast.errorDefault'), 'danger', 'bottom', true, 12000, undefined, true);
		};
	}

	function updateGroupNameIfEmpty(prompt: string|undefined = undefined, subType: GreeveSearchItemSubTypeInterface|undefined = undefined) {
		if (selectedGroup.value && selectedGroup.value.uuid && selectedGroup.value?.search_items?.length ===
				1 && (selectedGroup.value?.name?.length === 0 || selectedGroup.value?.getName()?.toLowerCase() ===
						'new')) {
			generateGroupName(selectedGroup.value?.uuid, prompt, false, subType);
		}
	}

	function streamOnEnd(timeoutId: number) {
		return async () => {
			updateGroupNameIfEmpty();
			isLoading.value = false;
			clearTimeout(timeoutId);
			if (onEventTimerId.value) {
				clearTimeout(onEventTimerId.value);
				onEventTimerId.value = undefined;
			}
			initQuotas(true).then(() => {
				calculateLowQuotaBanner(selectedChatType.value);
			});
		};
	}

	function handleStreamError(error: any, timeoutId: number) {
		let errorMessage = t('toast.errorDefault');
		if (error.status === 429) {
			errorMessage = t('toast.errorMaximum');
		}
		openToast(errorMessage, 'danger', 'bottom', true, 12000, undefined, true);
		clearTimeout(timeoutId);
		isLoading.value = false;
		calculateLowQuotaBanner(selectedChatType.value);
		console.error(error);
	}

	async function createChatStream(search: string, timeoutId: number) {
		return createAuthorizationItem(search, false, selectedGroup.value?.uuid, selectedGroup.value?.reference).
		then((response: SearchItemAuthorization | undefined) => {
			try {
				if (!response) {
					openToast(t('toast.errorDefault'), 'danger', 'top', true, 12000, undefined, true);
					return;
				}
				let searchItemNew = createEmptySearchItem_by_AuthorizationItem(response,
						GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_CHAT);
				const groupId = searchItemNew.search_item_group_id;
				if (!groupId) {
					throw Error('Invalid Group!');
				}
				addSearchItem(searchItemNew);
				clearTextInput();
				scrollToElement('prompt-' + searchItemNew.uuid, 0, 50);
				const hash = getHashByItemUuid(response.uuid);
				const params = {
					'hash': hash,
					'search_item_reference': response.reference,
					'search_group_id': response?.search_item_group_id,
				};
				scrollToResponse.value = false;
				isBeforeEndEventTriggered.value = false;
				dataCounter.value = 0;
				accumulatedData.value = [];

				getAssistantStreamApi().chatStream(
						params,
						streamOnEvent(searchItemNew),
						streamOnInteractionRequired(),
						streamOnBeforeEndEvent(searchItemNew),
						streamOnError(searchItemNew, timeoutId),
						streamOnEnd(timeoutId),
				);
			} catch (error: Error | any) {
				handleStreamError(error, timeoutId);
			}
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	async function correctTextStream(search: string, timeoutId: number) {
		return createAuthorizationItem(search, false, selectedGroup.value?.uuid, selectedGroup.value?.reference).
		then((response: SearchItemAuthorization | undefined) => {
			try {
				if (!response) {
					openToast(t('toast.errorDefault'), 'danger', 'top', true, 12000, undefined, true);
					return;
				}
				let searchItemNew = createEmptySearchItem_by_AuthorizationItem(response,
						GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_CHAT);
				const groupId = searchItemNew.search_item_group_id;
				if (!groupId) {
					throw Error('Invalid Group!');
				}
				addSearchItem(searchItemNew);
				clearTextInput();
				scrollToElement('prompt-' + searchItemNew.uuid, 0, 50);
				const hash = getHashByItemUuid(response.uuid);
				const params = {
					'hash': hash,
					'search_item_reference': response.reference,
					'search_group_id': response?.search_item_group_id,
				};
				scrollToResponse.value = false;
				isBeforeEndEventTriggered.value = false;
				dataCounter.value = 0;
				accumulatedData.value = [];

				getAssistantStreamApi().correctTextStream(
						params,
						streamOnEvent(searchItemNew),
						streamOnInteractionRequired(),
						streamOnBeforeEndEvent(searchItemNew),
						streamOnError(searchItemNew, timeoutId),
						streamOnEnd(timeoutId),
				);
			} catch (error: Error | any) {
				handleStreamError(error, timeoutId);
			}
		});
	}

	async function createVisionStream(search: string, mediaFileInfo: MediaFileInfo, timeoutId: number) {
		return createAuthorizationItem(search, false, selectedGroup.value?.uuid, selectedGroup.value?.reference).
		then((response: SearchItemAuthorization | undefined) => {
			try {
				if (!response) {
					openToast(t('toast.errorDefault'), 'danger', 'top', true, 12000, undefined, true);
					return;
				}
				let searchItemNew = createEmptySearchItem_by_AuthorizationItem(response,
						GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_CHAT);
				const groupId = searchItemNew.search_item_group_id;
				if (!groupId) {
					throw Error('Invalid Group!');
				}
				addSearchItem(searchItemNew);
				clearTextInput();
				scrollToElement('prompt-' + searchItemNew.uuid, 0, 50);
				const hash = getHashByItemUuid(response.uuid);
				const params = {
					'hash': hash,
					'search_item_reference': response.reference,
					'search_group_id': response?.search_item_group_id,
					'media_file_info': mediaFileInfo.toJson(),
				};
				scrollToResponse.value = false;
				isBeforeEndEventTriggered.value = false;
				dataCounter.value = 0;
				accumulatedData.value = [];

				return getAssistantStreamApi().visionStream(
						params,
						streamOnEvent(searchItemNew),
						streamOnInteractionRequired(),
						streamOnBeforeEndEvent(searchItemNew),
						streamOnError(searchItemNew, timeoutId),
						streamOnEnd(timeoutId),
				);
			} catch (error: Error | any) {
				handleStreamError(error, timeoutId);
			}
		});
	}

	async function searchPrompt(search = '', emptyPromptAllowed = false) {
		if (!search.length && !emptyPromptAllowed) {
			return;
		}

		if (isLoading.value) {
			//TODO show message unique with info that a process is currently running...please wait
			return;
		}

		isLoading.value = true;

		const timeoutFactor = inputMode.value === InputMode.UPLOAD ? 3 : 1;
		const timeoutId = setTimeout(() => {
			isLoading.value = false;
		}, (85000 * timeoutFactor));

		if ((!useAuth().isAuthenticated || !selectedGroup.value?.id) &&
				(!selectedGroup.value?.uuid || !selectedGroup.value?.reference)) {
			await openToast(t('toast.errorDefault'), 'danger', 'top', true, 12000, undefined, true);
			clearTimeout(timeoutId);
			isLoading.value = false;
			return;
		}

		if (inputMode.value !== InputMode.UPLOAD  && !await hasEnoughQuota(getGreeveSubType_by_Type(selectedChatType.value), search)) {
			await openToast(t('toast.errorNotEnoughQuota'), 'danger', 'top', true, 20000, undefined, true);
			clearTimeout(timeoutId);
			isLoading.value = false;
			return;
		}

		try {
			if (inputMode.value === InputMode.UPLOAD) {
				const itemUuid = uploadInteractionItem.value?.uuid;
				if (!itemUuid) {
					await openToast(t('toast.errorNotEnoughQuota'), 'danger', 'top', true, 20000, undefined, true);
					clearTimeout(timeoutId);
					isLoading.value = false;
					return;
				}
				if (!uploadInteractionItem.value) {
					await openToast(t('toast.errorNotEnoughQuota'), 'danger', 'top', true, 20000, undefined, true);
					clearTimeout(timeoutId);
					isLoading.value = false;
					return;
				}
				if (!uploadInteractionItem.value?.mediaFileInfo?.externalFileUrl) {
					await openToast(t('toast.errorNotEnoughQuota'), 'danger', 'top', true, 20000, undefined, true);
					clearTimeout(timeoutId);
					isLoading.value = false;
					return;
				}
				if (!await hasEnoughQuota(getGreeveSubType_by_Type(selectedChatType.value), search, uploadInteractionItem.value?.mediaFileInfo.externalFileUrl)) {
					await openToast(t('toast.errorNotEnoughQuota'), 'danger', 'top', true, 20000, undefined, true);
					clearTimeout(timeoutId);
					isLoading.value = false;
					return;
				}
				if (uploadInteractionItem.value?.subType === GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION) {
					createAssistantImageVariation(uploadInteractionItem.value?.mediaFileInfo, selectedGroup.value?.id, selectedGroup.value?.uuid, search, selectedGroup.value?.reference).then(() => {
						isLoading.value = false;
						clearTextInput();
						inputMode.value = InputMode.DEFAULT;
						uploadInteractionItem.value = undefined;
						isTextInputDisabled.value = false;
						calculateLowQuotaBanner(selectedChatType.value);
						clearTimeout(timeoutId);
					});
				} else if (uploadInteractionItem.value?.subType === GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION) {
					createVisionStream(search, uploadInteractionItem.value?.mediaFileInfo, timeoutId).then(() => {
						cancelNewUploadPrompt(itemUuid);
						inputMode.value = InputMode.DEFAULT;
						uploadInteractionItem.value = undefined;
						isTextInputDisabled.value = false;
						clearTimeout(timeoutId);
					});
				} else if (uploadInteractionItem.value?.subType === GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT) {
					return createAssistantSpeechToText(uploadInteractionItem.value?.mediaFileInfo, selectedGroup.value?.id, selectedGroup.value?.uuid, search, selectedGroup.value?.reference).then(() => {
						//TODO CHECK IF ERROR NO
						isLoading.value = false;
						clearTextInput();
						inputMode.value = InputMode.DEFAULT;
						uploadInteractionItem.value = undefined;
						isTextInputDisabled.value = false;
						calculateLowQuotaBanner(selectedChatType.value);
						cancelNewUploadPrompt(itemUuid);
						clearTimeout(timeoutId);
					}).catch((e) => {
							console.error(e);
							openToast(t('toast.errorDefault'), 'danger', 'top', true, 12000, undefined, true);
							clearTimeout(timeoutId);
							isLoading.value = false;
							calculateLowQuotaBanner(selectedChatType.value);
					});
				}
				return;
			}

			if (selectedChatType.value === ChatType.IMAGE) {
				createImage_by_Prompt(selectedGroup.value?.id, selectedGroup.value?.uuid, search,
						selectedGroup.value?.reference).then(() => {
					isLoading.value = false;
					clearTextInput();
					calculateLowQuotaBanner(selectedChatType.value);
					clearTimeout(timeoutId);
				});
			}

			if (selectedChatType.value === ChatType.TEXT_TO_SPEECH) {
				createSpeech_by_Prompt(selectedGroup.value?.id, selectedGroup.value?.uuid, search,
						selectedGroup.value?.reference).then(() => {
					isLoading.value = false;
					clearTextInput();
					calculateLowQuotaBanner(selectedChatType.value);
					clearTimeout(timeoutId);
				});
			}

			if (selectedChatType.value === ChatType.CHAT) {
				createChatStream(search, timeoutId);
			}
		} catch (e) {
			console.error(e);
			await openToast(t('toast.errorDefault'), 'danger', 'top', true, 12000, undefined, true);
			clearTimeout(timeoutId);
			isLoading.value = false;
		}
	}

	function registerChatBackgroundRunner() {
		if (!selectedGroup.value || autoCheckPendingItemsIntervalId.value) {
			stopChatBackgroundRunner();
			return;
		}

		const searchItemList = selectedGroup.value?.search_items;
		if (!searchItemList) {
			return;
		}

		const pendingSearchItemUuids = searchItemList.getInProgressUuids();
		if (!pendingSearchItemUuids || pendingSearchItemUuids.length === 0) {
			stopChatBackgroundRunner();
			return;
		}

		//TODO emergency stop after 60*15min
		autoCheckPendingItemsIntervalId.value = setInterval(() => {
			const pendingSearchItemUuids = searchItemList.getInProgressUuids();
			if (!pendingSearchItemUuids || pendingSearchItemUuids.length === 0) {
				stopChatBackgroundRunner();
				return;
			}

			if (autoCheckPendingItemsIntervalCount.value > 100) {
				stopChatBackgroundRunner();
				return;
			}
			initSearchItemByUuids(pendingSearchItemUuids, selectedGroup.value?.id);
			autoCheckPendingItemsIntervalCount.value++;
		}, 8000)
	}

	function stopChatBackgroundRunner() {
		if (!autoCheckPendingItemsIntervalId.value) {
			return;
		}

		clearInterval(autoCheckPendingItemsIntervalId.value);
		autoCheckPendingItemsIntervalId.value = undefined;
		autoCheckPendingItemsIntervalCount.value = 0;
	}

	async function initGroup(forceRefresh = false, forceGroupRefresh = false) {
		if (groupUuidByUrl.value) {
			selectedGroup.value = getGroupByUuid(groupUuidByUrl.value);
			if (!selectedGroup.value || forceGroupRefresh) {
				selectedGroup.value = await initSearchGroup_Uuid_Reference(groupUuidByUrl.value, groupReferenceByUrl.value);
			}
			if (selectedGroup.value && (selectedGroup.value.search_items?.length === 0 || forceRefresh)) {
				return initSearchItems_by_Group(selectedGroup.value);
			}
		}
	}

	async function loadChatByRoute(route: RouteLocationNormalizedLoaded, force = false) {
		const params = route.params;
		if (!params || !params.name || !params.uuid || !params.reference || !isAuthenticated) {
			isLoading.value = false;
			return;
		}

		groupUuidByUrl.value = Base64.decode(params.uuid.toString());
		groupReferenceByUrl.value = params.reference.toString();

		initGroup(force).then(() => {
			registerChatBackgroundRunner();
			isLoading.value = false;
		});
	}

	function getHashByItemUuid(itemUuid: string) {
		return Base64.encode(JSON.stringify({'search_item_uuid': itemUuid, 'user_id': useUser().user.value.id}));
	}

	function regenerateItem(itemUuid: string, itemSubType: GreeveSearchItemSubTypeInterface) {
		isLoading.value = true;
		const timeoutId = setTimeout(() => {
			isLoading.value = false;
		}, 35000);
		const errorSearchItem = getItemByUuid(itemUuid);
		if (!errorSearchItem) {
			return;
		}
		if (itemSubType === GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_CHAT) {
			try {
				const hash = getHashByItemUuid(itemUuid);
				const params = {
					'hash': hash,
				};
				scrollToResponse.value = false;
				isBeforeEndEventTriggered.value = false;
				dataCounter.value = 0;
				accumulatedData.value = [];

				getAssistantStreamApi().retryItemStream(
						params,
						streamOnEvent(errorSearchItem),
						streamOnInteractionRequired(),
						streamOnBeforeEndEvent(errorSearchItem),
						streamOnError(errorSearchItem, timeoutId),
						streamOnEnd(timeoutId),
				);
			} catch (error: Error | any) {
				handleStreamError(error, timeoutId);
			}
		} else {
			retryItem(itemUuid, false).then((resultItem) => {
				// deleteSearchItem(errorSearchItem);
				updateSearchItemDefaultByItem(errorSearchItem, resultItem);
				isLoading.value = false;
			})
		}
	}

	function calculateLowQuotaBanner(chatType: ChatType) {
		if (!isAuthenticated.value) {
			return;
		}
		const minQuota = 1;
		let availableQuota = 0;
		let quotaType = null;
		if (chatType === ChatType.CHAT) {
			quotaType = QuotaType.QUOTA_TYPE_FREE_GREEVE_ASSISTANT_CHAT_CREDITS;
		} else if (chatType === ChatType.IMAGE) {
			quotaType = QuotaType.QUOTA_TYPE_FREE_GREEVE_ASSISTANT_IMAGE_CREDITS;
		}

		if (quotaType) {
			if (!getQuotas.value) {
				return;
			}
			availableQuota = getQuotas.value.getAvailableQuotaAmountByType(quotaType);
			if (availableQuota <= minQuota) {
				availableQuota = getQuotas.value.getAvailableQuotaAmountByType(QuotaType.QUOTA_TYPE_CREDITS);
				if (availableQuota <= minQuota) {
					lowCreditsRedirectLink.value = '/profile/credits?tab=' + quotaType;
					hasLowCredits.value = true;
				}
			} else {
				hasLowCredits.value = false;
			}
		} else {
			hasLowCredits.value = false;
		}
	}

	watch(() => selectedChatType.value, (newSelectedChatType: ChatType) => {
		calculateLowQuotaBanner(newSelectedChatType);
	});

	watch(() => searchGroups.value,
			(newGroups: SearchGroupList | undefined, oldGroups: SearchGroupList | undefined) => {
				if (newGroups !== oldGroups && selectedGroup.value) {
					const newSelectedGroup = newGroups?.getItemByUuId(selectedGroup.value?.uuid, false);
					if (newSelectedGroup !== selectedGroup.value) {
						selectedGroup.value = newSelectedGroup;
					}
				}
			});

	const showNameOnNativeDevice = ref(true);
	let lastScrollTop = 0;
	const handleScroll = (event: any) => {
		if (!isMobileDevice()) {
			return;
		}
		const currentScrollTop = event.detail.scrollTop;

		// Check if we've scrolled down past the buffer distance
		if (currentScrollTop > lastScrollTop + buffer) {
			// Scrolling down
			showNameOnNativeDevice.value = false;
		}
		// Check if we've scrolled up past the buffer distance
		else if (currentScrollTop < lastScrollTop - buffer) {
			// Scrolling up
			showNameOnNativeDevice.value = true;
		}

		// Update lastScrollTop, but not when we're at the top
		lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
	};

	onMounted(() => {
		loadChatByRoute(route, true);
		calculateLowQuotaBanner(ChatType.CHAT);
		scrollToTop();
		// console.log("Total localStorage usage: " + useSystem().calculateTotalLocalStorageUsage() + " kb");
	});

	onBeforeUnmount(() => {
		stopChatBackgroundRunner();
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(InfoCard, {
      "info-key": "chat-info",
      "allow-to-close": true,
      "allowed-to-hide-permanently": true,
      class: "py-3 md:py-0 mt-1 sm:mt-0",
      title: _ctx.$t('chat2'),
      info: _ctx.$t('chatDescription'),
      img: "/assets/img/3d/chat.png"
    }, null, 8, ["title", "info"]),
    (hasLowCredits.value)
      ? (_openBlock(), _createBlock(ActionBanner, {
          key: "lowCreditsBanner",
          title: _ctx.$t('shop.quota.low_credits.banner.title'),
          description: _ctx.$t('shop.quota.low_credits.banner.description'),
          "action-title": _ctx.$t('shop.quota.low_credits.banner.action.title'),
          "redirect-link": lowCreditsRedirectLink.value
        }, null, 8, ["title", "description", "action-title", "redirect-link"]))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(LoadingLineAnimation, { "position-css": "top-[-3px] absolute w-full" }, null, 512), [
      [_vShow, isLoading.value]
    ]),
    _createVNode(_unref(IonRefresher), {
      slot: "fixed",
      onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => (handleRefresh($event)))
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(IonRefresherContent))
      ]),
      _: 1
    }),
    _withDirectives(_createElementVNode("div", null, [
      _withDirectives(_createVNode(LoadingLineBlurAnimation, { class: "fixed" }, null, 512), [
        [_vShow, isLoading.value]
      ])
    ], 512), [
      [_vShow, isLoading.value]
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_unref(isAuthenticated))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "flex -m-2.5 p-2.5 text-gray-400 lg:hidden",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(setMobileMenuSidebar)(true)))
                    }, [
                      _createElementVNode("span", _hoisted_8, [
                        _createVNode(_unref(Bars3Icon), {
                          class: "h-6 w-6",
                          "aria-hidden": "true"
                        })
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("a", _hoisted_10, [
                  _createElementVNode("strong", _hoisted_11, _toDisplayString(_ctx.$t('chat.modes.'+selectedChatType.value)) + " - Mode", 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("button", {
                  type: "button",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (filterOnlyPinnedItems.value = !filterOnlyPinnedItems.value))
                }, [
                  _createVNode(_unref(BookmarkIcon), {
                    class: _normalizeClass(['w-6 h-6 mx-auto text-gray-400', filterOnlyPinnedItems.value ? 'bookmarked' : ''])
                  }, null, 8, ["class"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_Transition, { name: "fade" }, {
                default: _withCtx(() => [
                  (selectedGroup.value && showNameOnNativeDevice.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _createElementVNode("span", _hoisted_15, _toDisplayString(selectedGroup.value.getName()), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("button", {
                type: "button",
                class: "flex -m-2.5 p-2.5 text-gray-400 lg:hidden",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(setMobileMenuSidebar)(true)))
              }, [
                _createElementVNode("span", _hoisted_20, [
                  _createVNode(_unref(Bars3Icon), {
                    class: "h-6 w-6",
                    "aria-hidden": "true"
                  })
                ])
              ])
            ])
          ]),
          (selectedGroup.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(selectedGroup.value.getName()), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (filterOnlyPinnedItems.value = !filterOnlyPinnedItems.value))
          }, [
            _createVNode(_unref(BookmarkIcon), {
              class: _normalizeClass([{'bookmarked': filterOnlyPinnedItems.value}, "w-6 h-6 mx-auto"])
            }, null, 8, ["class"])
          ]),
          (selectedGroup.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "justify-between",
                innerHTML: selectedGroup.value.getName()
              }, null, 8, _hoisted_23))
            : _createCommentVNode("", true)
        ])
      ]),
      (!_unref(isAuthenticated))
        ? (_openBlock(), _createBlock(PaywallOverlay, {
            key: 1,
            visible: true,
            "redirect-link": "/assistant"
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_24, [
            (selectedGroup.value)
              ? (_openBlock(), _createBlock(_unref(IonContent), {
                  key: 0,
                  id: "assistantContent",
                  "scroll-events": true,
                  onIonScroll: handleScroll
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((filterOnlyPinnedItems.value ? selectedGroup.value.search_items?.filterPinnedList(filterOnlyPinnedItems.value) : selectedGroup.value.search_items), (searchItem) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: searchItem.uuid
                      }, [
                        _createVNode(OutputContainer, {
                          "is-loading": isLoading.value,
                          "search-item": searchItem,
                          onRetryErrorItem: regenerateItem,
                          onDisableTextInput: _cache[5] || (_cache[5] = ($event: any) => (isTextInputDisabled.value = true)),
                          onEnableTextInput: _cache[6] || (_cache[6] = ($event: any) => (isTextInputDisabled.value = false)),
                          onCancelUploadPrompt: cancelNewUploadPrompt,
                          onUploadFinished: uploadFinished,
                          onUpdateUploadInteractionType: updateInteractionType
                        }, null, 8, ["is-loading", "search-item"])
                      ]))
                    }), 128))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])),
      _createElementVNode("div", _hoisted_25, [
        _createElementVNode("div", _hoisted_26, [
          (inputMode.value === 'default' && !isOnlySendButtonVisible.value)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (inputMode.value === 'default')
                  ? (_openBlock(), _createBlock(OutputTag, {
                      key: 0,
                      onUpdateSelectedType: setSelectedInputType,
                      isDisabled: !_unref(isAuthenticated)
                    }, null, 8, ["isDisabled"]))
                  : _createCommentVNode("", true),
                _createVNode(TextInput, {
                  "auto-grow": searchValue.value.length<=80,
                  "max-rows": searchValue.value.length > 700 ? 18 : (searchValue.value.length > 500 ? 12 : 1),
                  "search-default-value": searchValue.value,
                  "onUpdate:searchDefaultValue": _cache[7] || (_cache[7] = ($event: any) => ((searchValue).value = $event)),
                  isDisabled: isLoading.value || !_unref(isAuthenticated) || isTextInputDisabled.value,
                  mode: inputMode.value,
                  onOnEnter: searchPrompt,
                  onOnClear: clearTextInput,
                  onCreateUploadPrompt: addNewUploadPrompt,
                  onOnFileDrop: handleFileDrop,
                  onOnFilePaste: handleFilePaste
                }, null, 8, ["auto-grow", "max-rows", "search-default-value", "isDisabled", "mode"])
              ], 64))
            : (!isOnlySendButtonVisible.value)
              ? (_openBlock(), _createBlock(TextInput, {
                  key: 1,
                  "auto-grow": searchValue.value.length<=80,
                  "max-rows": searchValue.value.length > 700 ? 18 : (searchValue.value.length > 500 ? 12 : 1),
                  "search-default-value": searchValue.value,
                  "onUpdate:searchDefaultValue": _cache[8] || (_cache[8] = ($event: any) => ((searchValue).value = $event)),
                  isDisabled: isLoading.value || !_unref(isAuthenticated) || isTextInputDisabled.value,
                  mode: inputMode.value,
                  onOnEnter: searchPrompt,
                  onOnClear: clearTextInput,
                  onCreateUploadPrompt: addNewUploadPrompt,
                  onOnFileDrop: handleFileDrop,
                  onOnFilePaste: handleFilePaste
                }, null, 8, ["auto-grow", "max-rows", "search-default-value", "isDisabled", "mode"]))
              : _createCommentVNode("", true),
          (inputMode.value === 'upload' && isOnlySendButtonVisible.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                _createElementVNode("button", {
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (searchPrompt('', true))),
                  disabled: !hasUploadInteractionValidFile.value || isLoading.value,
                  class: _normalizeClass([(!hasUploadInteractionValidFile.value || isLoading.value) ? 'disabled:opacity-50' : '', 'inline-flex h-12 items-center w-full justify-center rounded-xl bg-gr-primary hover:bg-gr-primary2 py-1.5 px-10 text-sm font-semibold text-white shadow-sm focus:outline-none'])
                }, _toDisplayString(_ctx.$t('chat.interaction.'+ getUploadInteractionSubTypeForTranslation.value +'.submit')), 11, _hoisted_28),
                _createElementVNode("button", {
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (cancelCurrentUploadPrompt())),
                  disabled: isLoading.value,
                  class: _normalizeClass([(isLoading.value) ? 'disabled:opacity-50' : '', 'inline-flex h-12 items-center max-w-[30%] justify-center rounded-xl bg-gray-400 hover:bg-gr-gray-600 py-1.5 px-10 text-sm font-semibold text-white shadow-sm focus:outline-none'])
                }, _toDisplayString(_ctx.$t('chat.interaction.'+ getUploadInteractionSubTypeForTranslation.value +'.cancel')), 11, _hoisted_29)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}
}

})