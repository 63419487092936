import {
	GreeveSearchItemStateInterface,
	GreeveSearchItemSubTypeInterface,
	GreeveSearchItemTypeInterface,
	SearchItemInterface,
	SearchItemResponseErrorInterface,
} from '@/greeve/search/item/search_item.interface';
import {SearchGroup} from '@/greeve/search/group/search_group.type';
import MarkdownParser from '@/greeve/core/markdown_parser_new';
import useTranslation from '@/composable/core/useTranslation';
import useSystem from '@/composable/core/useSystem';

export const enum ChatType {
	CHAT = 'chat',
	IMAGE = 'image',
	TRANSCRIBE = 'transcribe',
	TEXT_TO_SPEECH = 'text_to_speech',
}

export abstract class AbstractSearchItem implements SearchItemInterface {
	id: number;
	search_item_group_id?: number;
	search_group?: SearchGroup;
	uuid: string;
	subType: GreeveSearchItemSubTypeInterface;
	state: GreeveSearchItemStateInterface;
	prompt?: string;
	reference?: string;
	group_reference?: string;
	is_pinned: boolean;
	request?: any;
	response?: any;
	data?: any;
	created_at?: Date;
	updated_at?: Date;

	abstract getType(): GreeveSearchItemTypeInterface;

	constructor(id: number, uuid: string, subType: GreeveSearchItemSubTypeInterface, state: GreeveSearchItemStateInterface, search_item_group_id: number|undefined = undefined, search_group: SearchGroup|undefined = undefined, reference: string|undefined = undefined, groupReference: string|undefined = undefined, prompt: string|undefined = undefined,  is_pinned = false, request: any = null, response: any = null, data: any = null, created_at?: Date, updated_at?: Date) {
		this.id = id;
		this.uuid = uuid;
		this.search_item_group_id = search_item_group_id;
		this.search_group = search_group;
		this.subType = subType;
		this.state = state;
		this.prompt = prompt;
		this.reference = reference;
		this.group_reference = groupReference;
		this.is_pinned = is_pinned;
		this.request = request;
		this.response = response;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}

	public hasError(): boolean {
		return this.state === GreeveSearchItemStateInterface.SEARCH_ITEM_STATE_ERROR;
	}

	public getErrorContent() {
		if (this.state === GreeveSearchItemStateInterface.SEARCH_ITEM_STATE_ERROR && this.response) {
			const response: SearchItemResponseErrorInterface = this.response;
			if (response.code === 404) {
				return useTranslation().t('search.item.error.'+this.getType()+'.'+this.subType+'.not_found.title');
			} else {
				return useTranslation().t('search.item.error.'+this.getType()+'.'+this.subType+'.general.title');
			}
			//TODO define good error messages in the backend!
			// return response.message;
		}
		return '';
	}

	public getContent(sanitizeValue = true)
	{
		if (!sanitizeValue) {
			return this.response;
		}
		return this.sanitizeExceptCodeTags(this.response ?? '');
	}

	public setContent(content: any)
	{
		this.response = content;
	}

	//TODO overwrite for TEXT and use markdown
	public addContent(content: any)
	{
		this.response = content;
	}

	public getResponse() {
		return this.response;
	}

	public hasCustomOutput(): boolean {
		return false;
	}

	public isInteractionSearchItemUpload(): boolean {
		return this.getType() === GreeveSearchItemTypeInterface.SEARCH_ITEM_TYPE_UPLOAD_INTERACTION;
	}

	public isInteractionSearchItem(): boolean {
		return this.getType() === GreeveSearchItemTypeInterface.SEARCH_ITEM_TYPE_INTERACTION;
	}

	public isInProgress(): boolean {
		return this.state === GreeveSearchItemStateInterface.SEARCH_ITEM_STATE_IN_PROGRESS;
	}

	public getInProgressMessage(): string {
		return useTranslation().t('search.item.in_progress.'+this.getType()+'.'+this.subType+'.title');
	}

	protected sanitizeContent(content: string) {
		return content.replace(/</g, '&lt;').replace(/>/g, '&gt;');
	}

	protected sanitizeExceptCodeTags(content: string) {
		try {
			if (!content ||content.length === 0) {
				return '';
			}
			// Temporarily replace <code> and </code> tags and <img> tags
			const tempContent = content
			.replace(/<code>/g, 'CODE_OPEN')
			.replace(/<\/code>/g, 'CODE_CLOSE')
			.replace(/<img /g, 'IMG_OPEN ')
			.replace(/\/>|>/g, ' IMG_CLOSE');

			// Apply sanitization
			let sanitizedContent = this.sanitizeContent(tempContent);
			sanitizedContent = sanitizedContent.replace(/(&lt;img [^]*?&gt;)/g, '</code>$1<code>');

			// Identify <img> tags inside <code> blocks, close the <code> block before
			// and open a new <code> block after the <img> tag if it was originally inside a <code> block
			sanitizedContent = sanitizedContent.replace(/(CODE_OPEN[^]*?)IMG_OPEN ([^]*?)IMG_CLOSE([^]*?)(CODE_CLOSE)?/g, (_, before, img, after, codeClose) => {
				return before + (codeClose ? 'CODE_CLOSE' : '') + '<img ' + img + '/>' + (codeClose ? 'CODE_OPEN' : '') + after;
			});

			// Replace placeholders back to original tags
			return sanitizedContent
			.replace(/CODE_OPEN/g, '<code>')
			.replace(/CODE_CLOSE/g, '</code>')
			.replace(/IMG_OPEN /g, '<img ')
			.replace(/ IMG_CLOSE/g, match => match === ' /IMG_CLOSE' ? '/>' : '>');
		} catch (e) {
			console.error('Sanitize Content Error ' + e, 'Content: ' + JSON.stringify(content));
		}
	}

	public getSharedMode(): ChatType {
		const subType = this.subType;
		if (subType === GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE) {
			return ChatType.IMAGE;
		}
		return ChatType.CHAT
	}

	public getSharedLink(): string {
		if (!this.search_group) {
			return '';
		}
		//TODO add specific item
		return '/assistant/chat/' + this.search_group.getUrlName() + '/' + this.search_group.getUuidEncoded() + '/' + this.search_group.reference;
	}

	private markdownParser: MarkdownParser | null = null;
	public getMarkdownParser = (): MarkdownParser => {
		if (!this.markdownParser) {
			this.markdownParser = new MarkdownParser();
		}
		return this.markdownParser;
	}

	public getRequest(): any {
		if (!this.request) {
			return undefined;
		}

		let requestObj;
		if (typeof this.request === 'object') {
			requestObj = this.request;
		} else if (useSystem().isJson(this.request)) {
			requestObj = JSON.parse(this.request) || '';
		} else {
			return undefined;
		}

		return requestObj;
	}
}