import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "justify-center items-center"
}
const _hoisted_3 = {
  key: 0,
  class: "text-gray-200 text-center"
}
const _hoisted_4 = { class: "mt-4 text-center font-bold text-bp-light" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "flex items-center justify-between gap-3 pb-1" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 0,
  class: "pb-2"
}
const _hoisted_10 = { class: "inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20" }
const _hoisted_11 = { class: "grid grid-cols-2 gap-3 sm:grid-cols-2" }
const _hoisted_12 = ["disabled"]
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }

import {computed, onMounted, onUnmounted, reactive, ref, watch} from 'vue';
import {AbstractSearchItem} from '@/greeve/search/item/abstract_search_item.type';
import OutputCard from '@/components/assistant/Output/OutputCard.vue';
import DefaultOutputElement from '@/components/assistant/Output/Elements/DefaultOutputElement.vue';
import AudioOutputElement from '@/components/assistant/Output/Elements/AudioOutputElement.vue';
import ImageOutputElement from '@/components/assistant/Output/Elements/ImageOutputElement.vue';
import {GreeveSearchItemSubTypeInterface} from '@/greeve/search/item/search_item.interface';
import {RadioGroup, RadioGroupLabel, RadioGroupOption} from '@headlessui/vue';
import UploadInteraction from '@/components/assistant/Output/Elements/Interaction/UploadInteraction.vue';
import {MediaFileInfo} from '@/greeve/media/media_file_info.type';
import ImageVariationOutputElement from '@/components/assistant/Output/Elements/ImageVariationOutputElement.vue';
import ImageVariationRequestImageElement
	from '@/components/assistant/Output/Elements/ImageVariationRequestImageElement.vue';
import ImageVisionInitialImageElement from '@/components/assistant/Output/Elements/ImageVisionRequestImageElement.vue';
import useTranslation from '@/composable/translation/useTranslation';
import InfoQuickViewDialog from '@/components/modal/InfoQuickViewDialog.vue';
import DefaultSpinner from '@/components/animations/DefaultSpinner.vue';
import useAssistant from '@/composable/greeve/useAssistant';


export default /*@__PURE__*/_defineComponent({
  __name: 'OutputContainer',
  props: {
	searchItem: {
		type: Object as () => AbstractSearchItem,
	},
	isLoading: {
		type: Boolean,
	},
},
  emits: [
	'retryErrorItem',
	'cancelUploadPrompt',
	'uploadFinished',
	'updateUploadInteractionType',
	'enableTextInput',
	'disableTextInput'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emits = __emit;

const contentSearchItem = ref<AbstractSearchItem | undefined>(props.searchItem);
const fileUploadLoading = ref(false);
const {t, getI18nArray} = useTranslation();
const {getEstimatedCredits} = useAssistant();
const uploadedFile = ref<MediaFileInfo | undefined>(undefined);

const selectedImageUploadData = reactive({
	count: 1,
});

const selectedUploadAction = ref();
const uploadActions =
		[
			// { globalType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE, subType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_EDIT, name: 'Edit Image', visible: true},
			// { globalType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE, subType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_REMOVE_BACKGROUND, name: 'Change Background', visible: true},
			{
				globalType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE,
				subType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION,
				name: t('assistant.output.upload.file.image.variations.button.title'),
				visible: true,
			},
			{
				globalType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE,
				subType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION,
				name: t('assistant.output.upload.file.image.vision.button.title'),
				visible: true,
			},
			{
				globalType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT,
				subType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT,
				name: t('assistant.output.upload.file.speech_to_text.variations.speech_to_text.button.title'),
				visible: true,
			},
		];
const estimatedCredits = ref(0);

const getUploadAction = computed(() => {
	const subType = contentSearchItem.value?.subType;
	if (subType) {
		const action = uploadActions.find(action => action.subType === subType);
		if (action) {
			return action;
		}
		return uploadActions.find(action => action.globalType === subType);
	}
	return undefined;
});

const isUploadActionListVisible = computed(() => {
	switch (contentSearchItem.value?.subType) {
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
			return false;
		default:
			return true;
	}
});

const getAllowedUploadActionListBySubType = computed(() => {
	if (!isUploadActionListVisible.value) {
		return [];
	}
	let filterActions = uploadActions.filter(action => action.globalType === contentSearchItem.value?.subType);
	if (!filterActions || !filterActions.length) {
		const actionBySubType = uploadActions.find(action => action.subType === contentSearchItem.value?.subType);
		if (actionBySubType && actionBySubType.globalType) {
			filterActions = uploadActions.filter(action => action.globalType === actionBySubType.globalType);
		}
	}
	return filterActions;
});

const isInfoDialogVisible = ref(false);

const GreeveSearchItemSubType = {
	SEARCH_ITEM_SUBTYPE_DEFAULT: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_DEFAULT,
	SEARCH_ITEM_SUBTYPE_CHAT: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_CHAT,
	SEARCH_ITEM_SUBTYPE_COMPLETION: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_COMPLETION,
	SEARCH_ITEM_SUBTYPE_IMAGE: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE,
	SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION,
	SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT,
	SEARCH_ITEM_SUBTYPE_TEXT_TO_SPEECH: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_TEXT_TO_SPEECH,
	SEARCH_ITEM_SUBTYPE_VISION: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION,
	SEARCH_ITEM_SUBTYPE_HTML: 'html',
	SEARCH_ITEM_SUBTYPE_RECIPE: 'recipe',
	SEARCH_ITEM_SUBTYPE_STUDY: 'study',
	SEARCH_ITEM_SUBTYPE_IMAGE_GENERATION: 'image_generation',
	SEARCH_ITEM_SUBTYPE_CODE: 'code',
	SEARCH_ITEM_SUBTYPE_BLOG: 'blog',
	SEARCH_ITEM_SUBTYPE_ARTICLE: 'article',
};

const currentLoadingText = ref<string>(''); // Aktueller Text, der angezeigt wird
let intervalId: number | undefined; // Intervall-ID für den Textwechsel

const startLoadingTextCycling = () => {
	if (!contentSearchItem.value?.isInteractionSearchItemUpload()) {
		return '';
	}

	let loadingType = 'default';
	switch (contentSearchItem.value?.subType) {
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION:
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION:
			loadingType = GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE;
			break;
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
			loadingType = GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT;
			break;
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_TEXT_TO_SPEECH:
			loadingType = GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_TEXT_TO_SPEECH;
			break;
	}

	const loadingTexts = getI18nArray(`assistant.output.upload.file.${loadingType}.loading_texts`);

	if (!loadingTexts || loadingTexts.length === 0) {
		currentLoadingText.value = '';
		return;
	}

	let index = 0;
	currentLoadingText.value = loadingTexts[index];

	intervalId = setInterval(() => {
		index = (index + 1) % loadingTexts.length;
		currentLoadingText.value = loadingTexts[index];
	}, 5000);
};

// Intervall beenden
const stopLoadingTextCycling = () => {
	if (intervalId) {
		clearInterval(intervalId);
	}
};

const getUploadTitle = computed(() => {
	if (!contentSearchItem.value?.isInteractionSearchItemUpload()) {
		return '';
	}

	switch (contentSearchItem.value?.subType) {
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION:
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION:
			return t('assistant.output.upload.file.image.title');
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
			return t('assistant.output.upload.file.speech_to_text.title');
		default:
			return '';
	}
});

const getUploadInfoTitle = computed(() => {
	if (!contentSearchItem.value?.isInteractionSearchItemUpload()) {
		return '';
	}

	switch (contentSearchItem.value?.subType) {
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION:
			return t('assistant.output.upload.file.image.variations.info.title');
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION:
			return t('assistant.output.upload.file.image.vision.info.title');
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
			return t('assistant.output.upload.file.speech_to_text.info.title');
		default:
			return '';
	}
});

const getUploadInfoSubTitle = computed(() => {
	if (!contentSearchItem.value?.isInteractionSearchItemUpload()) {
		return '';
	}

	switch (contentSearchItem.value?.subType) {
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION:
			return t('assistant.output.upload.file.image.variations.info.subTitle');
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION:
			return t('assistant.output.upload.file.image.vision.info.subTitle');
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
			return t('assistant.output.upload.file.speech_to_text.info.subTitle');
		default:
			return '';
	}
});

const getUploadLoadingDescription = computed(() => {
	if (!contentSearchItem.value?.isInteractionSearchItemUpload()) {
		return '';
	}

	switch (contentSearchItem.value?.subType) {
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION:
			return t('assistant.output.upload.file.image.variations.loading.description');
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION:
			return t('assistant.output.upload.file.image.vision.loading.description');
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
			return t('assistant.output.upload.file.speech_to_text.loading.description');
		default:
			return '';
	}
});

const getUploadInfoDescription = computed(() => {
	if (!contentSearchItem.value?.isInteractionSearchItemUpload()) {
		return '';
	}

	switch (contentSearchItem.value?.subType) {
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION:
			return t('assistant.output.upload.file.image.variations.info.description');
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION:
			return t('assistant.output.upload.file.image.vision.info.description');
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
			return t('assistant.output.upload.file.speech_to_text.info.description');
		default:
			return '';
	}
});

const getUploadAllowedFileTypeList = computed(() => {
	if (!contentSearchItem.value?.isInteractionSearchItemUpload()) {
		return [];
	}
	switch (contentSearchItem.value?.subType) {
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE:
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION:
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION:
			return ['image/jpeg', 'image/png', 'image/heic', 'image/heif', 'image/webp'];
		case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
			return ['audio/mpeg', 'audio/wav', 'audio/ogg', 'audio/mp4', 'audio/aac', 'audio/x-m4a', 'video/mp4'];
		default:
			return [];
	}
});

function retryErrorItem(searchItemUuid: string, subType: GreeveSearchItemSubTypeInterface) {
	emits('retryErrorItem', searchItemUuid, subType);
}

function cancelUploadPrompt(searchItemUuid: string | undefined) {
	estimatedCredits.value = 0;
	emits('cancelUploadPrompt', searchItemUuid);
	emits('enableTextInput');
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function uploadStarted(file: Blob) {
	startLoadingTextCycling();
	estimatedCredits.value = 0;
	fileUploadLoading.value = true;
	emits('updateUploadInteractionType', contentSearchItem.value?.uuid, selectedUploadAction.value?.subType);
	emits('disableTextInput');
}

async function calcualteEestimatedCredits(fileInfo: MediaFileInfo) {
	getEstimatedCredits(undefined, undefined, fileInfo.externalFileUrl).then((credits) => {
		if (!credits) {
			return;
		}
		estimatedCredits.value = credits.credits;
	});
}

function uploadFinished(fileInfo: MediaFileInfo) {
	stopLoadingTextCycling();
	calcualteEestimatedCredits(fileInfo);
	fileUploadLoading.value = false;
	uploadedFile.value = fileInfo;
	emits('uploadFinished', contentSearchItem.value?.uuid, fileInfo);
	emits('enableTextInput');
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function uploadError(e: any) {
	stopLoadingTextCycling();
	estimatedCredits.value = 0;
	fileUploadLoading.value = false;
	emits('enableTextInput');
}

watch(() => props.searchItem, (newItem) => {
	if (newItem && newItem !== contentSearchItem.value) {
		contentSearchItem.value = newItem;
	}
});

watch(() => selectedUploadAction.value, (newSelectedUploadAction, oldValue) => {
	if ((!oldValue || oldValue.subType !== newSelectedUploadAction.subType) && newSelectedUploadAction.subType) {
		emits('updateUploadInteractionType', contentSearchItem.value?.uuid, newSelectedUploadAction.subType);
	}
});

onMounted(() => {
	startLoadingTextCycling();
	if (contentSearchItem.value && contentSearchItem.value?.isInteractionSearchItemUpload()) {
		if (!selectedUploadAction.value) {
			selectedUploadAction.value = getUploadAction.value;
		}

		emits('updateUploadInteractionType', contentSearchItem.value?.uuid, selectedUploadAction.value?.subType);
	}
});

onUnmounted(() => {
	stopLoadingTextCycling();
});


return (_ctx: any,_cache: any) => {
  return (contentSearchItem.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (contentSearchItem.value?.isInteractionSearchItemUpload())
          ? (_openBlock(), _createBlock(OutputCard, {
              key: 0,
              "card-reference": 'prompt-'+contentSearchItem.value?.uuid,
              "card-content": '',
              "item-uuid": contentSearchItem.value?.uuid,
              "is-pinned": contentSearchItem.value?.is_pinned,
              onPinItem: _cache[4] || (_cache[4] = ($event: any) => (contentSearchItem.value?.is_pinned != contentSearchItem.value?.is_pinned)),
              "card-interaction": false,
              "card-info": false,
              "reverse-side": false
            }, {
              CardContent: _withCtx(() => [
                (__props.isLoading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      (currentLoadingText.value)
                        ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(currentLoadingText.value), 1))
                        : _createCommentVNode("", true),
                      _createVNode(DefaultSpinner, {
                        "show-spinner": true,
                        "show-spinner-alternative-text": "show-spinner-alternative-text",
                        "spinner-color": 'bg-gradient-to-b from-orange-500 to-yellow-300',
                        "spinner-size": 'h-32 w-32'
                      }),
                      _createElementVNode("div", _hoisted_4, _toDisplayString(getUploadLoadingDescription.value), 1)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.$t('assistant.output.upload.file.title')), 1),
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("h2", {
                          class: "text-lg font-medium leading-6 text-gray-400",
                          innerHTML: getUploadTitle.value
                        }, null, 8, _hoisted_8),
                        _createVNode(InfoQuickViewDialog, {
                          title: getUploadInfoTitle.value,
                          "sub-title": getUploadInfoSubTitle.value,
                          description: getUploadInfoDescription.value,
                          visible: isInfoDialogVisible.value,
                          onClose: _cache[0] || (_cache[0] = ($event: any) => (isInfoDialogVisible.value=false))
                        }, null, 8, ["title", "sub-title", "description", "visible"]),
                        _createElementVNode("a", {
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (isInfoDialogVisible.value = true)),
                          class: "cursor-pointer text-sm font-medium leading-6 text-gr-primary3 hover:text-gr-primary2"
                        }, _toDisplayString(_ctx.$t('assistant.output.upload.file.image.moreInfosHowToUse')), 1)
                      ]),
                      (estimatedCredits.value > 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_9, [
                            _cache[8] || (_cache[8] = _createTextVNode("Estimated Credits: ")),
                            _createElementVNode("span", _hoisted_10, _toDisplayString(estimatedCredits.value), 1)
                          ]))
                        : _createCommentVNode("", true),
                      _createVNode(UploadInteraction, {
                        "allowed-types": getUploadAllowedFileTypeList.value,
                        onOnUpload: uploadStarted,
                        onOnUploadEnd: uploadFinished,
                        onOnUploadError: uploadError,
                        "file-path": uploadedFile.value && uploadedFile.value.externalFileUrl ? uploadedFile.value.externalFileUrl : ''
                      }, null, 8, ["allowed-types", "file-path"]),
                      (isUploadActionListVisible.value && getAllowedUploadActionListBySubType.value.length > 0)
                        ? (_openBlock(), _createBlock(_unref(RadioGroup), {
                            key: 1,
                            modelValue: selectedUploadAction.value,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((selectedUploadAction).value = $event)),
                            class: _normalizeClass([fileUploadLoading.value ? 'cursor-not-allowed disabled:opacity-75' : '', 'mt-2'])
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(RadioGroupLabel), { class: "sr-only" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('assistant.output.upload.file.image.chooseAnImageAction')), 1)
                                ]),
                                _: 1
                              }),
                              _createElementVNode("div", _hoisted_11, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getAllowedUploadActionListBySubType.value, (uploadAction) => {
                                  return (_openBlock(), _createBlock(_unref(RadioGroupOption), {
                                    as: "template",
                                    key: uploadAction.subType,
                                    value: uploadAction,
                                    disabled: !uploadAction.visible
                                  }, {
                                    default: _withCtx(({ active, checked }) => [
                                      _createElementVNode("div", {
                                        class: _normalizeClass([uploadAction.visible && !fileUploadLoading.value ? 'cursor-pointer focus:outline-none' : 'cursor-not-allowed opacity-25', active ? 'ring-2 ring-gr-primary ring-offset-2' : '', checked ? 'bg-gr-primary text-white hover:bg-gr-primary-500' : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50', 'flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold uppercase sm:flex-1'])
                                      }, [
                                        _createVNode(_unref(RadioGroupLabel), { as: "span" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(uploadAction.name), 1)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ], 2)
                                    ]),
                                    _: 2
                                  }, 1032, ["value", "disabled"]))
                                }), 128))
                              ])
                            ]),
                            _: 1
                          }, 8, ["modelValue", "class"]))
                        : _createCommentVNode("", true),
                      _createElementVNode("button", {
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (cancelUploadPrompt(contentSearchItem.value?.uuid))),
                        type: "button",
                        disabled: fileUploadLoading.value,
                        class: _normalizeClass([fileUploadLoading.value ? 'cursor-not-allowed disabled:opacity-75' : '', 'mt-2 w-full rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'])
                      }, _toDisplayString(_ctx.$t('modal.cancelButtonText')), 11, _hoisted_12)
                    ]))
              ]),
              _: 1
            }, 8, ["card-reference", "item-uuid", "is-pinned"]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (contentSearchItem.value?.prompt && !contentSearchItem.value.hasCustomOutput())
                ? (_openBlock(), _createBlock(OutputCard, {
                    key: 0,
                    "card-reference": 'prompt-'+contentSearchItem.value.uuid,
                    "share-link": contentSearchItem.value.getSharedLink(),
                    "share-mode": contentSearchItem.value.getSharedMode(),
                    "card-content": contentSearchItem.value.prompt,
                    "item-uuid": contentSearchItem.value.uuid,
                    "is-pinned": contentSearchItem.value.is_pinned,
                    onPinItem: _cache[5] || (_cache[5] = ($event: any) => (contentSearchItem.value.is_pinned != contentSearchItem.value.is_pinned)),
                    "card-interaction": false,
                    "card-info": false,
                    "reverse-side": true
                  }, {
                    CardContent: _withCtx(() => [
                      _createVNode(DefaultOutputElement, {
                        content: contentSearchItem.value.prompt
                      }, null, 8, ["content"])
                    ]),
                    _: 1
                  }, 8, ["card-reference", "share-link", "share-mode", "card-content", "item-uuid", "is-pinned"]))
                : _createCommentVNode("", true),
              (contentSearchItem.value && contentSearchItem.value.hasCustomOutput())
                ? (_openBlock(), _createBlock(OutputCard, {
                    key: 1,
                    "card-reference": 'prompt-'+contentSearchItem.value.uuid,
                    "share-link": contentSearchItem.value.getSharedLink(),
                    "share-mode": contentSearchItem.value.getSharedMode(),
                    "card-content": contentSearchItem.value.prompt,
                    "item-uuid": contentSearchItem.value.uuid,
                    "is-pinned": contentSearchItem.value.is_pinned,
                    onPinItem: _cache[6] || (_cache[6] = ($event: any) => (contentSearchItem.value.is_pinned != contentSearchItem.value.is_pinned)),
                    "card-interaction": false,
                    "card-info": false,
                    "reverse-side": true
                  }, {
                    CardContent: _withCtx(() => [
                      (contentSearchItem.value?.subType === GreeveSearchItemSubType.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION)
                        ? (_openBlock(), _createBlock(ImageVariationRequestImageElement, {
                            key: 0,
                            "search-item": contentSearchItem.value
                          }, null, 8, ["search-item"]))
                        : (contentSearchItem.value?.subType === GreeveSearchItemSubType.SEARCH_ITEM_SUBTYPE_VISION)
                          ? (_openBlock(), _createBlock(ImageVisionInitialImageElement, {
                              key: 1,
                              "search-item": contentSearchItem.value
                            }, null, 8, ["search-item"]))
                          : (contentSearchItem.value?.subType === GreeveSearchItemSubType.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT)
                            ? (_openBlock(), _createBlock(AudioOutputElement, {
                                key: 2,
                                "search-item": contentSearchItem.value
                              }, null, 8, ["search-item"]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["card-reference", "share-link", "share-mode", "card-content", "item-uuid", "is-pinned"]))
                : _createCommentVNode("", true),
              _createVNode(OutputCard, {
                "card-reference": 'response-'+contentSearchItem.value?.uuid,
                "card-state": contentSearchItem.value?.state,
                "share-link": contentSearchItem.value?.getSharedLink(),
                "share-mode": contentSearchItem.value?.getSharedMode(),
                "search-item": __props.searchItem,
                "card-content": contentSearchItem.value?.getContent(),
                "item-uuid": contentSearchItem.value?.uuid,
                "is-pinned": contentSearchItem.value?.is_pinned,
                onPinItem: _cache[7] || (_cache[7] = ($event: any) => (contentSearchItem.value?.is_pinned != contentSearchItem.value?.is_pinned)),
                onRetryErrorItem: retryErrorItem,
                "card-interaction": true,
                "card-info": false,
                "reverse-side": false
              }, {
                CardContent: _withCtx(() => [
                  (contentSearchItem.value?.hasError())
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createVNode(DefaultOutputElement, {
                          content: contentSearchItem.value?.getErrorContent()
                        }, null, 8, ["content"])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        (contentSearchItem.value?.subType === GreeveSearchItemSubType.SEARCH_ITEM_SUBTYPE_TEXT_TO_SPEECH)
                          ? (_openBlock(), _createBlock(AudioOutputElement, {
                              key: 0,
                              src: contentSearchItem.value?.getContent()
                            }, null, 8, ["src"]))
                          : (contentSearchItem.value?.subType === GreeveSearchItemSubType.SEARCH_ITEM_SUBTYPE_IMAGE)
                            ? (_openBlock(), _createBlock(ImageOutputElement, {
                                key: 1,
                                response: contentSearchItem.value?.getResponse()
                              }, null, 8, ["response"]))
                            : (contentSearchItem.value?.subType === GreeveSearchItemSubType.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION)
                              ? (_openBlock(), _createBlock(ImageVariationOutputElement, {
                                  key: 2,
                                  "search-item": contentSearchItem.value
                                }, null, 8, ["search-item"]))
                              : (_openBlock(), _createBlock(DefaultOutputElement, {
                                  key: 3,
                                  content: contentSearchItem.value?.getContent()
                                }, null, 8, ["content"]))
                      ]))
                ]),
                _: 1
              }, 8, ["card-reference", "card-state", "share-link", "share-mode", "search-item", "card-content", "item-uuid", "is-pinned"])
            ], 64))
      ]))
    : _createCommentVNode("", true)
}
}

})