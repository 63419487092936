<template>
	<div v-show="showSpinner === true || showSpinnerAlternativeText === true"
		 class="grid place-items-center h-screen justify-center sm:p-1 md:p-3" style="height: auto">
		<div v-if="showSpinner === true">
			<div class="flex justify-center items-center">
				<div :class="['animate-spin rounded-full border-t-2 border-b-2', spinnerColor + ' ' + spinnerSize]"></div>
			</div>
		</div>
		<div v-else-if="showSpinner === false && showSpinnerAlternativeText === true">
			{{ $t("loading.DefaultSpinner.spinnerAlternativeText") }}
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
	name: 'DefaultSpinner',
	components: {},
	props: {
		showSpinner: {
			type: Boolean,
			default: false,
		},
		showSpinnerAlternativeText: {
			type: Boolean,
			default: false,
		},
		spinnerColor: {
			type: String,
			default: "border-bp-light",
		},
		spinnerSize: {
			type: String,
			default: "h-32 w-32",
		}
	},
	setup() {
		return {};
	}
})
</script>

<style scoped>

</style>