<template>
	<div>
		<button type="button" @click="bookMarkAction"
						class="rounded-md px-2 py-1.5 text-sm font-medium dark:text-gray-400 focus:outline-none">
			<BookmarkIcon :class="{'bookmarked': isBookMarked}"
										class="w-6 h-6 mx-auto"></BookmarkIcon>
		</button>

		<button v-if="shareMode === 'image'" type="button" @click="downloadImageByDescription"
						class="rounded-md px-2 py-1.5 text-sm font-medium dark:text-gray-400 focus:outline-none">
			<ArrowDownIcon class="w-6 h-6 mx-auto"></ArrowDownIcon>
		</button>
		<button v-else type="button" @click="copyContentToClipboard"
						class="rounded-md px-2 py-1.5 text-sm font-medium dark:text-gray-400 focus:outline-none">
			<ClipboardIcon class="w-6 h-6 mx-auto"></ClipboardIcon>
		</button>
	</div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import useSystem from '@/composable/core/useSystem';
import {BookmarkIcon, ClipboardIcon, ArrowDownIcon} from '@heroicons/vue/24/outline';
import useTranslation from '@/composable/translation/useTranslation';
import useToastMessage from '@/composable/core/useToastMessage';
import i18n from '@/i18n';
import {ChatType} from '@/greeve/search/item/abstract_search_item.type';

export default defineComponent({
	name: 'InteractionAction',
	props: {
		reference: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
		dialogTitle: {
			type: String,
			//TODO add Translation
			default: 'Look at my greeve search result 😉',
		},
		shareImage: {
			type: String,
			default: '',
		},
		sharedParam: {
			type: String,
			default: 'shared', //if you want to use a group reference change the prop to groupShared
		},
		shareLink: {
			type: String,
			default: '',
		},
		shareMode: {
			type: String as () => ChatType,
			default: () => ChatType.CHAT,
		},
		isBookMarked: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		BookmarkIcon, ClipboardIcon, ArrowDownIcon,
	},
	emits: ['rate', 'bookMark'],
	setup(props, {emit}) {
		const {t} = useTranslation();
		const {copyContent, copyImageToClipboard, extractImageUrl_by_HtmlImage, downloadImage} = useSystem();

		function bookMarkAction() {
			emit('bookMark', !props.isBookMarked);
		}

		function copyContentToClipboard()
		{
			if (props.shareMode === ChatType.IMAGE) {
				copyImageToClipboard(props.description);
			} else {
				copyContent(props.description);
			}
		}

		function downloadImageByDescription()
		{
			const url = extractImageUrl_by_HtmlImage(props.description);
			if (url) {
				downloadImage(url);
			} else {
				useToastMessage().openToast(i18n.global.t("toast.errorDownloadImage"), 'danger', 'top', true, 10000, undefined, true)
			}
		}

		return {t, copyContentToClipboard, bookMarkAction, downloadImageByDescription};
	},
});
</script>

<style lang="scss">
</style>
