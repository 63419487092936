import {loadStripe, Stripe, StripeConstructorOptions} from '@stripe/stripe-js';
import {computed, ComputedRef, onBeforeMount, ref} from 'vue';
import store from '@/store';
import {CheckoutSteps} from '@/greeve/shop/checkout/steps/steps.type';
import router from '@/router';
import {ProductList} from '@/greeve/shop/product/product_list.type';
import {GreeveApiShop} from '@/greeve/api/shop';
import {Product} from '@/greeve/shop/product/product.type';
import {Price} from '@/greeve/shop/product/price.type';
import {PriceList} from '@/greeve/shop/product/price_list.type';
import useCartFactory from '@/composable/greeve/useCartFactory';
import {CartItem} from '@/greeve/shop/cart/item/cart_item.type';
import useUser from '@/composable/greeve/useUser';
import {Cart} from '@/greeve/shop/cart/cart.type';
import useAlert from '@/composable/core/useAlert';
import {
	CheckoutSession,
} from '@/greeve/shop/checkout/session/checkout_session.type';
import {CartState} from '@/greeve/shop/cart/cart.interface';
import useSystem from '@/composable/core/useSystem';
import {
	GreeveProductPriceSubType,
	GreeveProductPriceType,
} from '@/greeve/shop/product/price.interface';
import {DiscountObject} from '@/greeve/shop/cart/discount/discount.interface';
import {
	AbstractDiscountItem
} from '@/greeve/shop/cart/discount/abstract_discount_item.type';
import {ResponseError} from '@/greeve/api/api.interfaces';
import i18n from '@/i18n';
import {UserAddress} from '@/greeve/user/address/user_address.type';
import {getCurrentDate} from '@/greeve/core/date';

const {getCartFactory, getDiscountItemFactory} = useCartFactory();
const {user} = useUser();
const {addError, clearErrorAlert} = useAlert();

export const CURRENT_STEP = 'current';
export const UPCOMING_STEP = 'upcoming';
export const COMPLETED_STEP = 'completed';


export default function useShop() {
	const isCartInitialized: ComputedRef<Cart> = computed(() => store.getters['shop/isCartInitialized']);
	const cart: ComputedRef<Cart> = computed(() => store.getters['shop/getCart']);
	const checkoutSteps: ComputedRef<CheckoutSteps> = computed(() => store.getters['shop/getSteps']);
	const activeStepId: ComputedRef<number> = computed(() => store.getters['shop/getActiveStepId']);
	const products: ComputedRef<ProductList> = computed(() => store.getters['shop/getProducts']);
	const lastProductRefreshDate: ComputedRef<Date> = computed(() => store.getters['shop/getLastProductRefreshDate']);
	const prices: ComputedRef<PriceList> = computed(() => store.getters['shop/getPrices']);
	const isProductsLoading = ref(false);
	const isPricesLoading = ref(false);

	function getStripePaymentElementOptions(clientSecret: string) {
		const appearance = {
			theme: 'flat', //stripe, night, flat, none
			variables: {
				//TODO
				// colorPrimary: '#0570de',
				// colorBackground: '#ffffff',
				// colorText: '#30313d',
				// colorDanger: '#df1b41',
				// fontFamily: 'Ideal Sans, system-ui, sans-serif',
				// spacingUnit: '2px',
				// borderRadius: '4px',
			}
		};
		return {clientSecret, appearance}
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	async function getSetupIntent(force = false): Promise<string>
	{
		//TODO if intent empty then create new one save in shop/storage
		try {
			let setupIntent = '';
			const result = await GreeveApiShop.getSetupIntent();
			if (result && result.client_secret) {
				setupIntent = result.client_secret;
			} else {
				throw new Error('Nothing found, ' + JSON.stringify(result));
			}
			return setupIntent;
		} catch (error: Error | any) {
			console.error(error);
			throw error;
		} finally {
			//
		}
	}

	async function getStripe(options?: StripeConstructorOptions | undefined): Promise<Stripe|null>
	{
		return loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY, options);
	}

	function setCheckoutSteps(updateCheckoutSteps: CheckoutSteps|null = null) {
		if (!updateCheckoutSteps && !checkoutSteps.value) {
			updateCheckoutSteps = new CheckoutSteps();
		} else if (checkoutSteps.value) {
			updateCheckoutSteps = checkoutSteps.value;
		}
		store.commit('shop/setCheckoutSteps', updateCheckoutSteps);
	}

	function setCheckoutSession(checkoutSession: CheckoutSession|null = null) {
		store.commit('shop/setCheckoutSession', checkoutSession);
	}

	function isLastProductRefreshOlderThan(hours: number, date: Date|undefined = undefined): boolean {
		if (!date) {
			date = getCurrentDate();
		}
		if (!lastProductRefreshDate.value) {
			return true;
		}
		return lastProductRefreshDate.value.getTime() < date.getTime() - (hours * 60) * 60 * 1000;
	}

	function setLastProductRefreshDate(date: Date|undefined = undefined) {
		if (!date) {
			date = getCurrentDate();
		}
		store.commit('shop/setLastProductRefreshDate', date);
	}

	function updateStepStateById(stepId: number, state: string) {
		const udpateCheckoutData = {stepId, state};
		store.dispatch('shop/updateCheckoutStepState', udpateCheckoutData);
	}

	function allowOnlyOneTypeOfProduct(): boolean {
		return true;
	}

	function removeNotAllowedProducts(product_id: string, type: GreeveProductPriceType, subType: GreeveProductPriceSubType|undefined = undefined, force = false) {
		if (!cart.value) {
			return;
		}
		cart.value?.items.forEach((item: CartItem) => {
			if (force || (item.product_id !== product_id && type !== item.getPrice()?.type && subType !== item.getPrice()?.sub_type) ) {
				removeCartItem_by_Id(item.id);
			}
		});
	}

	//TODO add discount
	async function addDiscountToCart(discountCode: string, discount_id = '', object = DiscountObject.PROMO_CODE, coupon: any = {})
	{
		const discountItem = getDiscountItemFactory().createDiscountItemByResponse({id: discount_id, object: object, promotionCode: discountCode, coupon: coupon})
		addDiscountItemToCart(discountItem);
	}

	async function isDiscountValid(code: string) {
		return await GreeveApiShop.getPromotionDiscountItem(code).then((result) =>
				{
					console.log("discount", result)
					if (result && result.id) {
						return true;
					} else {
						throw new Error('Nothing found, ' + JSON.stringify(result));
					}
				},
				error => {
					console.error(error);
					return false
				});
	}

	async function addDiscountItemToCart(discountItem: AbstractDiscountItem)
	{
		try {
			if (!discountItem.promotionCode) {
				const emptyDiscount = {
					id: "",
					title: "Error",
					message: "Empty Discount",
					active: true,
				};
				addError(emptyDiscount);
				return;
			}
			GreeveApiShop.getPromotionDiscountItem(discountItem.promotionCode).then((result) =>
			{
				if (result && result.id) {
					store.dispatch('shop/addDiscountToCart', {discountItem: result});
				} else {
					throw new Error('Nothing found, ' + JSON.stringify(result));
				}
			},
			error => {
				console.error(error);
				throw error;
			});
		} catch (error: Error | any) {
			console.error(error);
			const errorAddDiscount = {
				id: "",
				title: "Error",
				message: "Cannot add discount! " + error,
				active: true,
			};
			addError(errorAddDiscount);
			throw error;
		} finally {
			//
		}
	}

	function removeDiscountFromCart(discountItem: AbstractDiscountItem)
	{
		store.dispatch('shop/removeDiscountItem_by_Id', discountItem.id);
	}

	function addProductToCart(product_id: string, type: GreeveProductPriceType = GreeveProductPriceType.ONE_TIME_PRICE, subType: GreeveProductPriceSubType|undefined = undefined) {
		if (allowOnlyOneTypeOfProduct()) {
			removeNotAllowedProducts(product_id, type, subType);
		}
		store.dispatch('shop/addProductToCart', {product_id: product_id});
	}

	function addRecurringProductToCart(product_id: string, subType: GreeveProductPriceSubType|undefined = undefined) {
		const item = cart.value?.items.getItemByProductId(product_id);
		const product = products.value.getProduct_by_Id_And_PriceType(product_id, getRecurringPriceType(), subType);
		if (allowOnlyOneTypeOfProduct()) {
			removeNotAllowedProducts(product_id, getRecurringPriceType(), undefined, true);
		}
		if (item) {
			if (item.getPrice()?.sub_type !== product?.price.sub_type) {
				removeCartItem_by_Id(item.id);
				store.dispatch('shop/addExistingProductToCartItem', {cartItemId: item.id, product: product});
			} else {
				changeCartItemCount(item, 1);
			}
		} else {
			store.dispatch('shop/addProductToCart', {product_id: product_id, priceType: getRecurringPriceType(), priceSubType: subType});
		}
	}


	function setCurrency(currencyCode: string) {
		store.commit('shop/setCurrency', currencyCode);
	}

	function initCustomerByUser() {
		const customer = useCartFactory().getCustomerFactory().createCustomerByUser(user.value);
		store.commit('shop/setCustomer', customer);
	}

	function updateCustomerAddressByStripeAddress(stripeAddress: any, saveAddress = false) {
		const customerAddress = useCartFactory().getCustomerFactory().createCustomerAddressByStripeAddress(stripeAddress);
		customerAddress.addSaveAddressOption(saveAddress);
		if (!cart.value.customer) {
			//TODO check after first step
			initCustomerByUser();
		}
		store.commit('shop/setCustomerAddress', customerAddress);
	}

	function removeCartItem_by_Id(cartItemId: string) {
		store.dispatch('shop/removeCartItem_by_Id', cartItemId);
	}

	function hasValidCart(): boolean {
		try {
			if (cart.value && cart.value?.items && cart.value.isAddressValidated() && [CartState.DONE, CartState.NEW].includes(cart.value.state)) {
				return true;
			}
		} catch (error: any) {
			console.error(error);
		}

		return false;
	}

	async function resetCart() {
		store.dispatch('shop/resetCheckoutSteps');
		await initCart(true);
	}

	async function cancelCheckout(redirectToLink = '/pricing') {
		//TODO check reset steps in cart!
		store.dispatch('shop/resetCheckoutSteps');
		await initCart(true);

		if (redirectToLink) {
			await router.push(redirectToLink);
		}
	}

	async function initProducts(forceRefresh = false) {
		if ( (!products.value || products.value.length < 1 || forceRefresh) && !isProductsLoading.value) {
			isProductsLoading.value = true;
			const productList = await GreeveApiShop.getAllProducts();
			store.commit('shop/setProducts', productList);
			setLastProductRefreshDate();
			isProductsLoading.value = false;
		}
	}

	async function initPrices(forceRefresh = false) {
		if ( (!prices.value || prices.value.length < 1 || forceRefresh) && !isPricesLoading.value) {
			isPricesLoading.value = true;
			const priceList = await GreeveApiShop.getPrices();
			store.commit('shop/setPrices', priceList);
			isPricesLoading.value = false;
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	function changeCartItemCount(cartItem: CartItem, itemQuantity: number|null = null, event: any = null) {
		if (!itemQuantity) {
			itemQuantity = cartItem.quantity;
		}
		store.dispatch('shop/changeCartItemCount', {cartItem_id: cartItem.id, count: itemQuantity});
	}

	async function payOrder() {
		clearErrorAlert();
		if (cart.value && cart.value.checkoutSession && !cart.value.checkoutSession.isSessionExpired()) {
			window.location.href = cart.value.checkoutSession.url;
		} else {
			cart.value.total_amount = Number(cart.value.getTotalAmountCalculated()); //TODO check mabey recalc in module after add product
			cart.value.tax_amount = Number(cart.value.getTotalTaxAmountCalculated());
			return GreeveApiShop.getCheckoutSessionByCart(cart.value).then((response) =>
				{
					setCheckoutSession(response);
					if (response?.url) {
						window.location.href = response.url;
					} else {
						throw Error('Invalid Data!');
					}
				},
				(error:any) => {
					console.error(error);
					let errorMessage = error && error.message ? error.message : "There was a technical error! Please try again";
						if (error instanceof ResponseError && error.errorCode) {
							const errorLtmCode = "error." + error.errorCode;
							errorMessage = i18n.global.t(errorLtmCode);
							if (!errorMessage || errorMessage === errorLtmCode) {
								errorMessage = "There was a technical error! Please try again";
							}
					}

						//Todo check
					const errorCreateOrderLink = {
						id: "",
						title: "Error",
						message: errorMessage,
						active: true,
					};
					addError(errorCreateOrderLink);
					useSystem().scrollToTop();
					return;
				}
			);
		}
	}

	function getRecurringPriceType(): GreeveProductPriceType.RECURRING_PRICE
	{
		return GreeveProductPriceType.RECURRING_PRICE;
	}

	function getOneTimePriceType(): GreeveProductPriceType.ONE_TIME_PRICE
	{
		return GreeveProductPriceType.ONE_TIME_PRICE;
	}

	function getRecurringMonthlySubType(): GreeveProductPriceSubType.RECURRING_MONTHLY
	{
		return GreeveProductPriceSubType.RECURRING_MONTHLY;
	}

	function getRecurringYearlySubType(): GreeveProductPriceSubType.RECURRING_YEARLY
	{
		return GreeveProductPriceSubType.RECURRING_YEARLY;
	}

	function getProduct_by_ProductId(product_Id: string): Product|undefined {
		return store.getters['shop/getProduct_by_Id'](product_Id);
	}
	function getProduct_by_ProductId_And_PriceId(product_Id: string, price_Id: string): Product|undefined {
		return store.getters['shop/getProduct_by_ProductId_And_PriceId'](product_Id, price_Id);
	}

	function getProduct_by_PriceId(price_Id: string): Product|undefined {
		return store.getters['shop/getProduct_by_PriceId'](price_Id);
	}

	function getPrice_by_PriceId(price_Id: string): Price|undefined {
		return store.getters['shop/getPrice_by_Id'](price_Id);
	}

	const initCartAddress = async function (userAddress: UserAddress) {
		if (isCartInitialized.value && userAddress) {
			const customerAddress = useCartFactory().getCustomerFactory().createCustomerAddressByUserAddress(userAddress);
			store.commit('shop/setCustomerAddress', customerAddress);
		}
	}

	const initCart = async function (forceReload = false) {
		if (!isCartInitialized.value || forceReload) {
			store.commit('shop/setCart',getCartFactory().createEmptyCart());
		}
	}

	onBeforeMount(() => {
		initProducts().then(() => {
			//TODO
		})
		initCart().then(() => {
			// console.log("cart initialized");
		});
		//TODO check initProducts()
	});

	return {
		cart,
		getStripe,
		getStripePaymentElementOptions,
		getSetupIntent,
		checkoutSteps,
		setCheckoutSteps,
		activeStepId,
		updateStepStateById,
		cancelCheckout,
		initProducts,
		products,
		getProduct_by_ProductId,
		initPrices,
		prices,
		getPrice_by_PriceId,
		addProductToCart,
		addRecurringProductToCart,
		removeCartItem_by_Id,
		changeCartItemCount,
		initCustomerByUser,
		updateCustomerAddressByStripeAddress,
		payOrder,
		resetCart,
		hasValidCart,
		setCurrency,
		getRecurringPriceType,
		getOneTimePriceType,
		getRecurringMonthlySubType,
		getRecurringYearlySubType,
		getProduct_by_ProductId_And_PriceId,
		getProduct_by_PriceId,
		addDiscountToCart,
		removeDiscountFromCart,
		isDiscountValid,
		initCartAddress,
		setLastProductRefreshDate,
		isLastProductRefreshOlderThan,
	}
}
