import { marked } from "marked";

class MarkdownParser {
	private _cssNamespace = "";

	constructor(cssNamespace = "") {
		this._cssNamespace = cssNamespace;
		marked.setOptions({
			gfm: true,
			breaks: true,
		});

		const renderer = new marked.Renderer();

		// Override table renderer
		renderer.table = (header, body) => {
			return `
        <div class="overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left text-gray-500 border-collapse">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50">
              ${header}
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              ${body}
            </tbody>
          </table>
        </div>
      `;
		};

		renderer.tablerow = (content) => `<tr>${content}</tr>`;

		renderer.tablecell = (content, flags) => {
			const tag = flags.header ? "th" : "td";
			const className = flags.header
					? "px-6 py-3 font-semibold text-gray-900"
					: "px-6 py-4 text-gray-700";
			return `<${tag} class="${className}">${content}</${tag}>`;
		};

		renderer.paragraph = (text) => {
			// Remove extra spacing above tables
			return `<p class="mb-4">${text}</p>`;
		};

		// Custom render for code blocks
		renderer.code = (code, language) => {
			const langClass = language
					? `language-${language.toLowerCase()}`
					: "language-plaintext";
			return `
        <pre class="bg-gray-900 text-gray-100 p-4 rounded-lg overflow-x-auto shadow-md">
          <code class="${langClass}">${code}</code>
        </pre>
      `;
		};

		// Custom render for inline code
		renderer.codespan = (code) => {
			return `<code class="bg-gray-100 text-red-600 px-1.5 py-0.5 rounded-md font-mono text-sm">${code}</code>`;
		};

		marked.use({ renderer });
	}

	get cssNamespace(): string {
		return this._cssNamespace;
	}

	set cssNamespace(value: string) {
		this._cssNamespace = value;
	}

	/**
	 * Converts markdown text to HTML using the `marked` library.
	 * @param markdownText The markdown string to convert.
	 * @returns The HTML representation of the markdown.
	 */
	public markdownToHtml(markdownText: string): string {
		if (!markdownText) {
			return "";
		}

		markdownText = this.applyCustomColorSyntax(markdownText);

		// Use `marked` to parse the markdown
		const html: any = marked.parse(markdownText);

		// Optionally wrap with a namespace div
		return this._cssNamespace
				? `<div class="${this._cssNamespace}">${html}</div>`
				: html;
	}

	/**
	 * Strips markdown syntax and returns plain text.
	 * @param markdownText The markdown string to process.
	 * @returns The plain text representation.
	 */
	public markdownToText(markdownText: string): string {
		if (!markdownText) {
			return "";
		}

		// Strip markdown syntax
		const strippedText = markdownText
		.replace(/(!?\[.*?\]\(.*?\))/g, "") // Remove links and images
		.replace(/([*_~`]+)/g, "") // Remove emphasis and code syntax
		.replace(/^>+/gm, "") // Remove blockquote syntax
		.replace(/^#+\s+/gm, "") // Remove heading syntax
		.replace(/- \[[ xX]\]\s+/g, "") // Remove checklist markers
		.replace(/[-*]\s+/g, "") // Remove unordered list markers
		.replace(/\d+\.\s+/g, "") // Remove ordered list markers
		.replace(/(\n|\r)+/g, " ") // Replace newlines with spaces
		.trim();

		return strippedText;
	}

	/**
	 * Generates a table of contents from markdown text.
	 * @param markdownText The markdown string to parse.
	 * @returns An array of TOC items with title, level, and anchor info.
	 */
	public getTableOfContentsByMarkdown(markdownText: string): Array<{
		title: string;
		level: number;
		anchor: string;
	}> {
		const lines = markdownText.split("\n");
		const toc = [];

		for (const line of lines) {
			const match = /^(#{1,6})\s+(.+)/.exec(line);
			if (match) {
				const level = match[1].length;
				const title = match[2];
				const anchor = title
				.toLowerCase()
				.replace(/[^a-z0-9]+/g, "-")
				.replace(/^-|-$/g, ""); // Clean up leading/trailing dashes

				toc.push({ title, level, anchor });
			}
		}

		return toc;
	}

	private applyCustomColorSyntax(markdownText: string): string {
		const tailwindColors: Record<string, string> = {
			red: "text-red-500",
			blue: "text-blue-500",
			green: "text-green-500",
			yellow: "text-yellow-500",
			purple: "text-purple-500",
			pink: "text-pink-500",
			gray: "text-gray-500",
		};

		return markdownText.replace(
				/\{color:([a-zA-Z]+)\}([\s\S]*?)\{\/color\}/g,
				(match, color, text) => {
					const tailwindClass = tailwindColors[color.toLowerCase()] || "text-gray-700"; // Default to gray if color is unknown
					return `<span class="${tailwindClass}">${text}</span>`;
				}
		);
	}
}

export default MarkdownParser;
